import React, {useState } from 'react'
import {withRouter} from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import ToolbarComponent from "../components/ui/toolbar/toolbar.component"
import DrawerComponent from "../components/ui/drawer/drawer.component"
import FooterComponent from "../components/ui/footer/footer.component"
import BreadcrumbComponent from '../components/ui/breadcrumb/breadcrumb.component'
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import bgPattern from '../theme/images/pattern-bg-gestionale.jpg'

const useStyles = makeStyles(theme => ({
    gridContainer:{
        position: 'relative',
        minHeight: '100vh',
        backgroundImage: `url(${bgPattern})`        
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: 0,
        paddingRight: 0
    },
    content:{        
        display: 'flex',
        marginTop: theme.spacing(4)        
    }
}))

const DefaultLayout = (props) => {
    const {children, toolbarPosition, breadcrumb } = props
    const [open, toggleDrawer] = useState(false)
    const toggle = () => { toggleDrawer(!open) }      
    const classes = useStyles()
    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>                
                <ToolbarComponent position={toolbarPosition} toggleDrawer={toggle}/>
                <DrawerComponent open={open} toggleDrawer={toggle}/>
            </Grid>
            <Grid item xs={12} style={{marginTop: '65px', minHeight: '90vh'}}>
                <Container maxWidth="lg" className={classes.container}>
                    {breadcrumb===true && <BreadcrumbComponent />}
                    <main className={classes.content}>
                        {children}
                    </main>
                </Container>                
            </Grid>            
            <Grid item xs={12}>
                <FooterComponent/>
            </Grid>
        </Grid>
    )
}

DefaultLayout.defaultProps = {
    toolbarPosition: 'absolute'
}

DefaultLayout.propTypes = {
    toolbarPosition: PropTypes.string
}

export default withRouter(DefaultLayout)
