import React, { Fragment, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import FeatureTitle from '@meetico/mappify/map/feature-content/feature-title/feature-title.component'
import InfoMapComponent from './info-map/info-map.component'
import InfoGeneralComponent from './info-general/info-general.component'
import InfoAppearanceComponent from './info-appearance/info-appearance.component'
import InfoAttributesComponent from './info-attributes/info-attributes.component'

import { socket } from '@meetico/mappify/layouts/map.layout'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%'      
    },
    closeIcon:{
        position: 'absolute',
        top: '1.5rem',
        right: '1.5rem',
        color: theme.palette.common.black,
        padding:0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

const InfoComponent = ({ map, feature, map_id, onClosePanel }) => {
    const classes = useStyles()

    useEffect(() => {        
        socket.on('update-feature-success', () => {
            toast.success('Informazioni salvate con successo')            
            socket.emit('get-feature-info', { map_id, feature_uuid: feature.uuid })
        })
        return () => {
            socket.off('update-feature-success')
        }
    },[feature.uuid, map_id])

    return (
        <Fragment>
            <FeatureTitle feature={feature} />
            <IconButton className={classes.closeIcon} onClick={onClosePanel}>
                <CloseIcon />
            </IconButton>            
            <InfoMapComponent map_id={map_id} map={map} feature={feature} />            
            <InfoGeneralComponent map_id={map_id} feature={feature} />
            {feature.type!=='marker' && <InfoAppearanceComponent map_id={map_id} feature={feature} />}
            <InfoAttributesComponent map_id={map_id} feature={feature} />
        </Fragment>       
    )
}

export default InfoComponent