export const PROJECT_PREFIX = 'NMQ_MPF_'
export const ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const UPLOADS = process.env.REACT_APP_UPLOADS_ENDPOINT

export const SITE_MAP = [
    {
        label: 'Mappe',
        icon: 'map',
        path: '/',
        expansion: []
    },
    // {
    //     label: 'Gestione utenza',
    //     expansion: [
    //         {
    //             label: 'Gestione Utenti',
    //             icon:'face',
    //             path: 'utenti',
    //             items: [
    //                 {
    //                     label: 'Utenti',
    //                     link: '/gestisci-utenze/utenti'
    //                 },
    //                 {
    //                     label: 'Gruppi',
    //                     link: '/gruppi'
    //                 }
    //             ]
    //         }                                   
    //     ]
    // },        
];

export const mapBaseLayers = [
    {
        name: 'OpenStreetMap.Mapnik',
        attribution: '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        checked: true
    },
    {
        name: 'OpenStreetMap.BlackAndWhite',
        attribution: '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
        checked: false
    }
]