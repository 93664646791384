import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ShowIcon from '@material-ui/icons/Visibility'
import HideIcon from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    visibilityIcon: {
        marginRight: 9,
        color: theme.palette.common.black
    },
    actionsIcon:{
        color: theme.palette.common.black
    }
}))

const MapWmsListItem = ({ wms, _onToggleWms, _onEdit, _onDelete }) => {
    const classes = useStyles()                                                 
    return (                        
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Grid container alignItems="center" justify="flex-start">
                    <Grid item>                        
                        <Tooltip placement="top" title={"Click to toggle WMS"}>
                            <IconButton size="small" onClick={() => { _onToggleWms(wms.uuid) }} className={classes.visibilityIcon}>
                                {wms.active ? <ShowIcon /> : <HideIcon />}
                            </IconButton>
                        </Tooltip>                        
                    </Grid>
                    <Grid item>
                        {(wms.name.length < 15) && <Typography variant="body2">{wms.name}</Typography>}
                        {(wms.name.length >= 15) && (
                            <Tooltip placement="top" title={wms.name}>
                                <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 150}}>                                
                                    <Typography noWrap variant="body2">{wms.name}</Typography>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip placement="top" title={`${wms.url}:${wms.layer}`}>
                            <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 150}}>                                
                                <Typography noWrap variant="caption">{wms.url}:<b>{wms.layer}</b></Typography>
                            </div>
                        </Tooltip>                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Tooltip placement="top" title="Edit WMS">
                    <IconButton size="small" onClick={() => _onEdit(wms)} className={classes.actionsIcon}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Delete WMS">
                    <IconButton size="small" onClick={() => _onDelete(wms)} className={classes.actionsIcon}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )    
}

export default MapWmsListItem