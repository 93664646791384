import React, { useState, useMemo, Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ForumIcon from '@material-ui/icons/Forum'

import TabPanel from '@meetico/mappify/ui/tab-panel/tab-panel.component'
import ChatComponent from '@meetico/mappify/map/map-content/chat/chat.component'
import FilesComponent from '@meetico/mappify/map/map-content/files/files.component'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        position:'relative',                
        width: '50vw',
        padding: `0.8rem 1.5rem` 
    },
    tab: {
        color: theme.palette.common.white
    },
    tabSelected:{
        color: `${theme.palette.common.white} !important`
    },
    tabs:{
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        width: '100%',
        height: 'auto',
        boxSizing: 'border-box',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20
    }
}));

const MapGenericDrawer = ({ map_id, drawerInitSection, onClose }) => {
    const classes = useStyles();
    const [value, setValue] = useState(drawerInitSection)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderTabComponent = useMemo(() => {        
        return (
            <Fragment>                
                <TabPanel value={value} index={0}>
                    <FilesComponent map_id={map_id} onClosePanel={onClose} />                    
                </TabPanel>                
                <TabPanel value={value} index={1}>
                    <ChatComponent map_id={map_id} onClosePanel={onClose} />
                </TabPanel>
            </Fragment>
        )
    },[value, map_id, onClose])

    return (
        <Paper square className={classes.root}>  
            {renderTabComponent}
            <Tabs
                className={classes.tabs}                
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"                
                aria-label="generic map tabs"
                centered
            >
                <Tab className={classes.tab} icon={<NoteAddIcon />} />
                <Tab className={classes.tab} classes={{ selected: classes.tabSelected}} icon={<ForumIcon />} />                
            </Tabs>
        </Paper>
    )
}

export default MapGenericDrawer