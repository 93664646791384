import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import { socket } from '@meetico/mappify/layouts/map.layout'
import ReactQuill from 'react-quill'
import { rteFormats, rteModules } from '@meetico/mappify/core/utils'
import 'react-quill/dist/quill.snow.css'
import '@meetico/mappify/core/styles/Quill.css'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%',
        marginTop: theme.spacing(2)
    },
    sectionTitle:{
        fontSize: 16,
        fontWeight: 'bold'
    },
    textField: {
        width: '100%'      
    },
    editIcon: {
        color: theme.palette.common.black
    },
    readOnlyField: {
        fontSize: 12,
        background: '#f9f9f9',
        borderRadius: 15,
        padding: '0.5rem 1rem',
        marginBottom: '0.5rem',
        height: 'auto',
        minHeight: 20,
        maxHeight: 250,
        overflowY: "auto",
        flexWrap: 'nowrap',        
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1',            
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    }
}))

function showDescriptionMarkup(description){
    return {
        __html: description
    }
}

const InfoGeneral = ({ map_id, feature }) => {
    
    const classes = useStyles()
    const [inputState, setInputState] = useState('read')
    const [info, setInfo] = useState({})
    const [form, setForm] = useState({
        uuid: feature.uuid,
        name: '',
        description: '',
        color: ''      
    })

    useEffect(() => {        
        socket.emit('get-feature-info', { map_id, feature_uuid: feature.uuid })        

        socket.on('feature-info-update', data => {
            if(data.feature_uuid===feature.uuid){
                setForm(data)
                setInfo(data)
            }            
        })

        socket.on('update-feature-success', () => {
            setInputState('read')
        })
        return () => {
            socket.off('feature-info-update')
            socket.off('update-feature-success')
        }
    },[feature.uuid, map_id])    

    const closeEdit = () => {
        setForm(info)
        setInputState('read')
    }

    const handleInputChange = (name, value) => {        
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const info = { map_id, layer_uuid: feature.layer_uuid, uuid:feature.uuid, name: form.name, description: form.description, color: form.color}        
        socket.emit('update-feature-info', info)
    }

    return (
        <Grid container direction="column" spacing={1} justify="center" className={classes.root}>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">                    
                <Grid item>
                    <Typography className={classes.sectionTitle}>General Info</Typography>
                </Grid>
                <Grid item>
                    <IconButton className={classes.editIcon} edge="end" onClick={() => inputState==='read' ? setInputState('edit') : closeEdit()}>
                        {inputState==='read' ? <EditIcon /> : <CloseIcon />}
                    </IconButton>
                </Grid>
            </Grid>                
            <Grid item xs={12}>                    
                {inputState!=='edit' ? (
                    <div className={classes.readOnlyField}>{form.name ? form.name : 'Click on edit button to edit the feature name'}</div>
                ) : (
                    <TextField
                        variant="outlined"
                        name="name"
                        id="name"
                        placeholder="Feature Name"
                        onChange={(e) => handleInputChange('name',e.target.value)}
                        value={form.name}
                        className={classes.textField}
                        margin="dense"
                        color="secondary"
                    />
                )}                
            </Grid>
            <Grid item xs={12}>
                {inputState!=='edit' ? 
                    form.description ?
                        <div className={classes.readOnlyField} dangerouslySetInnerHTML={showDescriptionMarkup(form.description)} />
                    : 
                        <div className={classes.readOnlyField}>Click on edit button to edit the feature description</div>                
                :         
                    <ReactQuill 
                        placeholder="Place feature description here"
                        theme="snow" 
                        value={form.description} 
                        onChange={(description) => handleInputChange('description',description)}
                        modules={rteModules}
                        formats={rteFormats}
                    />
                }                
            </Grid>             
            {inputState==='edit' ? (
                <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button type="submit" variant="text" color="primary" onClick={closeEdit}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSubmit}>Save</Button>
                    </Grid>
                </Grid> 
            ) : null
            }
        </Grid>
    )
}

export default InfoGeneral