import get from 'lodash/get';
import moment from "moment";


export const rteModules = {
    toolbar: [
        ['bold', 'italic', 'underline','strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean']
    ]
}

export  const rteFormats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent','link'];

export const searchFilter = (filter, columns) => (item) => {
    try {
        if (filter) {
            filter = filter.toString().toLowerCase();
            return columns.map(({id}) => {
                let itemValue = get(item, id);
                if (itemValue) {
                    itemValue = itemValue.toString().toLowerCase();
                    if(!isNaN(Date.parse(itemValue))) {
                        const date = moment(itemValue).format('DD MM YYYY');
                        return date.search(filter) !== -1 || itemValue.search(filter) !== -1;
                    }
                    return itemValue.search(filter) !== -1;
                } else return false;
            }).reduce((arr, result) => arr || result)
        } else return true
    } catch (e) {
        console.error(e);
        return true;
    }
};

export const validEmail = email => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true
    return false
}