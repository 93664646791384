import React, { useEffect, useState, useContext, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { StoreContext } from '@meetico/mappify/context/store.context'
import _ from "lodash"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TablePagination from "@material-ui/core/TablePagination"
import makeStyles from "@material-ui/core/styles/makeStyles"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Toolbar from "@material-ui/core/Toolbar"
import TextField from "@material-ui/core/TextField"
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import GroupIcon from '@material-ui/icons/Group'
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmDialog, { dialogInitConfig } from '@meetico/mappify/components/dialogs/dialog.component'
import ShareDialog from '@meetico/mappify/components/dialogs/share.component'

const columns = [
    {id: 'id', label: '#', colSpan: 1, sortable: false},
    {id: 'name', label: 'Name', colSpan: 1, sortable: true},
    {id: 'lat', label: 'Lat', colSpan: 1, sortable: true},
    {id: 'long', label: 'Long', colSpan: 2, sortable: true}
]

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '10px 15px'
    },
    tableWrapper: {
        overflow: 'auto',
    },
    activeUserIcon: {
        color: theme.palette.success.main
    },
    inactiveUserIcon: {
        color: theme.palette.error.main
    },
    groupLink: {
        textDecoration: 'none',
        color: theme.palette.secondary.main
    }
}))


const MapsTable = ({ history }) => {

    const {auth: { isAuth }, apiService, dataset} = useContext(StoreContext)
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [maps, setMaps] = useState(null)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [tableRows, setTableRows] = useState([])
    const [filter, setFilter] = useState('')
    const [dialogConfig, setDialogConfig] = useState(dialogInitConfig)
    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const [sharedMap, setSharedMap] = useState()

    const closeDialog = () => setDialogConfig(dialogInitConfig)

    const fetchMaps = useCallback(() => apiService('/maps','maps','get',{}),[apiService])

    useEffect(() => {
        if(isAuth) fetchMaps()
    }, [isAuth,dataset.invitations,fetchMaps])

    useEffect(() => {
        setPage(0)
        if(maps) setTableRows(maps.filter(u => {
            return (
                u.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                u.lat.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                u.long.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            )
        }))
    }, [filter,maps])

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }
    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }
    const viewMap = (map) => {
        history.push(`/map/${map.id}`)
    }
    const editMap = (map) => {
        history.push(`/map/${map.id}/edit`)
    }
    const deleteMap = async (selectedItem) => {        
        await apiService(`/map/${selectedItem}`,'','delete',{})
        fetchMaps()
        setDialogConfig(dialogInitConfig)
    }
    const deleteMapHandler = (map) => {        
        setDialogConfig({...dialogConfig, open: true, selectedItem: map.id, callback: deleteMap})        
    }

    const openShareDialog = map => {
        setSharedMap(map)
        setShareDialogOpen(true)
    }

    const shareDialogClose = (callback) => {
        setSharedMap(null)
        setShareDialogOpen(false)
        if(callback==='refetch') fetchMaps()
    }

    useEffect(() => {
        if(dataset.maps && dataset.maps.maps){
            setMaps(dataset.maps.maps)
            setTableRows(dataset.maps.maps)            
        }
    },[dataset.maps])

    if(!maps) return <CircularProgress size="200" />

    return (
        <div className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}} onChange={(e) => setFilter(e.target.value)} InputProps={{endAdornment:<InputAdornment position="end"><Icon>search</Icon></InputAdornment> }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(({id, align, colSpan, sortable, label}) => (
                                <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                    {sortable ? (
                                        <TableSortLabel
                                            active={orderBy === id}
                                            direction={order}
                                            onClick={createSortHandler(id)}>
                                            {label}
                                        </TableSortLabel>
                                    ) : label }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((map,index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align={"left"}>{map.id}</TableCell>
                                        <TableCell align={"left"} valign="middle">
                                            {(map.users.length>1) ? (
                                                <Tooltip title="Mappa Condivisa" placement="top">
                                                    <IconButton>
                                                        <GroupIcon />
                                                    </IconButton>
                                                </Tooltip>            
                                            ) : null}
                                            {map.name}
                                        </TableCell>
                                        <TableCell align={"left"}>{map.lat}</TableCell>
                                        <TableCell align={"left"}>{map.long}</TableCell>
                                        <TableCell align={"right"}>
                                            <Tooltip title="Condividi Mappa" placement="top">
                                                <IconButton onClick={() => openShareDialog(map)}>
                                                    <Icon>person_add</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Apri Mappa" placement="top">
                                                <IconButton onClick={() => viewMap(map)}>
                                                    <Icon>visibility</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Gestione Documentale - Coming Soon" placement="top">
                                                <IconButton onClick={() => {}}>
                                                    <Icon>note_add</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Modifica Mappa" placement="top">
                                                <IconButton onClick={() => editMap(map)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Elimina Mappa" placement="top">
                                                <IconButton onClick={() => deleteMapHandler(map)}>
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {(filter && !tableRows.length) ? (
                            <TableRow hover tabIndex={-1}>
                                <TableCell align={"center"} colSpan={columns.length}>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        Non sono state trovate mappe per la ricerca effettuata
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {!maps.length ? (
                            <TableRow hover tabIndex={-1}>
                                <TableCell align={"center"} colSpan={columns.length}>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        Non hai ancora creato mappe. Cosa aspetti! Crea subito la tua prima mappa cliccando sul pulsante "Crea Nuova Mappa"
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                labelRowsPerPage="Elementi per pagina"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'previous page' }}
                nextIconButtonProps={{ 'aria-label': 'next page' }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <ConfirmDialog openDialog={dialogConfig.open} title={dialogConfig.title} text={dialogConfig.text} confirmAction={dialogConfig.callback} selectedItem={dialogConfig.selectedItem} cancelAction={closeDialog}/>
            {(shareDialogOpen && sharedMap) && <ShareDialog open={shareDialogOpen} closeDialog={shareDialogClose} map={sharedMap} fetchMaps={fetchMaps} />}
        </div>
    )

}

export default withRouter(MapsTable)
