import React from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({    
    formContainer:{
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    textField: {
        fontSize: 12,        
        transform: `translate(0, 27px) scale(1)`
    },
    textFieldShrinked:{
        transform: 'translate(0, 1.5px) scale(0.75)'
    },
    textFieldWithMargin:{
        marginTop: 19,
        marginBottom: 0,
        fontSize: `12px !important`
    },
    actionButtonContainer:{
        textAlign: 'center'
    },
    formIcon:{
        color: theme.palette.common.black
    }
}))

const types = [{label: 'Text', value:'text'}, {label:'Link', value:'link'}, {label: 'Password',  value:'password'}]

const FeatureAttributeForm = ({ form, handleInputChange, closeForm, handleSubmit }) => {
    const classes = useStyles()
    return (
        <Grid item xs={12} container direction="row" justify="space-between" alignItems="center" spacing={1} className={classes.formContainer}>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    select         
                    label="Type"                 
                    InputLabelProps={{className: classes.textField, classes: { shrink: classes.textFieldShrinked}}}  
                    // className={classes.textField}
                    value={form.type}
                    margin="none"
                    color="secondary"
                    onChange={(e) => handleInputChange('type',e.target.value)}                            
                >                              
                    {types.map((type) => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    placeholder="Label"
                    onChange={(e) => handleInputChange('label',e.target.value)}
                    value={form.label}
                    InputProps={{className: classes.textFieldWithMargin}}
                    // className={classes.textFieldWithMargin}
                    margin="dense"
                    color="secondary"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    placeholder="Value"
                    onChange={(e) => handleInputChange('value',e.target.value)}
                    value={form.value}
                    InputProps={{className: classes.textFieldWithMargin}}
                    // className={classes.textFieldWithMargin}
                    margin="dense"
                    color="secondary"
                />
            </Grid>
            <Grid item xs={1} className={classes.actionButtonContainer}>                
                <Tooltip placement="top" title="Save Attribute">
                    <IconButton onClick={handleSubmit} className={classes.formIcon} edge="end"><SaveIcon /></IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={1} className={classes.actionButtonContainer}>
                <Tooltip placement="top" title="Close Form">
                    <IconButton onClick={closeForm} className={classes.formIcon} edge="end"><CloseIcon /></IconButton>
                </Tooltip>
            </Grid>                    
        </Grid>
    )
}

export default FeatureAttributeForm