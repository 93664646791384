import React from 'react'
import moment from 'moment'
import { UPLOADS } from '@meetico/mappify/core/config'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'

import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import DocIcon from '@material-ui/icons/InsertDriveFile'
import PhotoIcon from '@material-ui/icons/Photo'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    listItem:{
        padding: '8px 16px 8px 0',
        width: '100%',        
    },
    attachmentAvatar: {
        border: '1px solid #ddd'
    },
    attachmentSubtitle: {
        color: "#666666"
    },
    attachmentIcon: {
        color: theme.palette.common.black
    },
}))

const FeatureFileListItem = ({ item, downloadFileHandler, deleteAttachment, divider = true }) => {
    
    const classes = useStyles()

    const openFile = () => window.open(`${UPLOADS}/${item.filename}`,'_blank')

    return (
        <React.Fragment>
            <ListItem className={classes.listItem}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item>
                                {item.type==='image' ? (
                                    <Avatar className={classes.attachmentAvatar} alt="attachment preview" src={`${UPLOADS}/${item.filename}`}> <PhotoIcon /> </Avatar> 
                                ) :   (
                                    <Avatar className={classes.attachmentAvatar}><DocIcon /></Avatar> 
                                )}
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2">{`${item.name}`}</Typography>
                                <Typography variant="caption" className={classes.attachmentSubtitle}>{`${item.attachment_user.name} ${item.attachment_user.surname} - ${moment(item.date_of_creation).format('DD-MM-YYYY')}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        {item.type==='image' ? (
                            <Grid item>
                                <Tooltip placement="top" title="View image">
                                    <IconButton onClick={openFile} className={classes.attachmentIcon} edge="end">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <Tooltip placement="top" title="Download file">
                                <IconButton className={classes.attachmentIcon} edge="end" onClick={() => downloadFileHandler(item.filename)}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip placement="top" title="Delete file">
                                <IconButton className={classes.attachmentIcon} edge="end" onClick={() => deleteAttachment(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>                                
            </ListItem>
            {divider && <Divider variant="middle" component="li" />}
        </React.Fragment>
    )
}

export default FeatureFileListItem