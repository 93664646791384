import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import PageTitle from '@meetico/mappify/ui/page-title/page-title.component'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(8),      
    },
    content:{
        textAlign: 'center'
    },
    button:{
        marginTop: theme.spacing(2)
    }
}))

const ServerDown = () => {
    const classes = useStyles()
    const [seconds, setSeconds] = useState(15)
    const countDown = (i) => {
        setInterval(() => {
            setSeconds(i)
            i-- || window.location.reload()
        }, 1000);
    }
    useEffect(() => {
        countDown(15)
    },[])
    return (
        <Card className={classes.container}>
            <CardContent>
                <Grid container spacing={4}> 
                    <Grid item xs={12}>
                        <PageTitle title="Ops!" subtitle="Qualcosa non sta funzionando come dovrebbe" />
                    </Grid>           
                    <Grid item xs={12} className={classes.content}>
                        <Typography variant="body1">Stiamo riscontrando problemi a contattare il server</Typography>
                        <Typography variant="body1">Nuovo tentativo in {seconds} {seconds===1 ? 'secondo' : 'secondi'}</Typography>
                        <Button className={classes.button} variant="contained" color="primary" onClick={() => window.location.reload()}>Riprova a connetterti</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>        
    )
}

export default ServerDown