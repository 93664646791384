import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, withRouter} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    primaryText: {
        fontFamily: 'Poppins',
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    whiteDivider: {
        backgroundColor: theme.palette.primary.main,
    },
    footer: {
        margin: 0,
        width: '100%'        
    } 
}));
const FooterComponent = () => {
    const classes = useStyles();
    return (
        <Grid container direction={"column"} alignContent={"center"} className={classes.footer} spacing={2}>            
            <Grid item xs={12} md={11}>
                <Grid container justify={"space-between"} alignItems={"center"} spacing={4}>
                    <Grid item>
                        <Typography className={classes.primaryText}>Mappify - Mappe Collaborative</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.primaryText}>
                            <Link component={RouterLink} to={'/privacy-policy'} target="_blank" color={"inherit"}>
                                Privacy Policy 
                            </Link> | {`Copyright © Meetico LTD ${new Date().getFullYear()}.`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(FooterComponent);
