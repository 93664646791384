import React, { forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const dialogInitConfig = {
    open: false,
    title: 'Conferma Azione',
    text: 'Vuoi davvero procedere con l\'azione richiesta?',
    action: null,
    selectedItem: null
}

const ConfirmDialog = ({ openDialog, title, text, confirmAction, cancelAction, selectedItem }) => {        
    const cancelActionHandler = () => {
        cancelAction()        
    }
    const confirmActionHandler = (selectedItem) => {
        confirmAction(selectedItem)        
    };

    return (    
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={cancelActionHandler}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelActionHandler} color="secondary" variant="outlined">
                    Annulla
                </Button>
                <Button onClick={() => confirmActionHandler(selectedItem)} color="primary">
                    Conferma Azione
                </Button>
            </DialogActions>
      </Dialog>
    );
}

export default ConfirmDialog