import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react'
import _ from 'lodash'
import { StoreContext } from '@meetico/mappify/context/store.context'
import Grid from '@material-ui/core/Grid'
// import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
// import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import Slide from '@material-ui/core/Slide'

// import UploadIcon from '@material-ui/icons/CloudUpload'
// import AddCircleIcon from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

import { makeStyles } from '@material-ui/core/styles'
import { socket } from '@meetico/mappify/layouts/map.layout'


import FeatureTitle from '@meetico/mappify/map/feature-content/feature-title/feature-title.component'
// import FeatureFileForm from '@meetico/mappify/forms/feature-file.form'
import FeatureFileListItem from '@meetico/mappify/lists/feature-file-item/feature-file-item.list'

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%',
        padding: theme.spacing(1)
    },
    closeIcon:{
        position: 'absolute',
        top: 0,
        right: 0
    },
    addIcon:{
        padding:0,
        color: theme.palette.common.black
    }, 
    searchInput:{
        marginTop: theme.spacing(2)
    },
    listContainer:{
        maxHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    },
    list:{
        background: '#f1f1f1',
        padding: theme.spacing(2),
        margin: 0,
        marginTop: 15,
        width: '100%',        
        borderRadius: 20,
        border: `1px solid ${theme.palette.grey[200]}`
        // boxShadow: theme.shadows[1]
    },
    listSubheader:{
        padding: 0
    },
    listFeatureTitle: {
        fontSize: 14
    },
    listItem:{
        padding: '8px 16px 8px 0',
        width: '100%',        
    },
    attachmentAvatar: {
        border: '1px solid #ddd'
    },
    attachmentSubtitle: {
        color: "#666666"
    }
}))

const AttachmentsComponent = ({ map_id, onClosePanel }) => {
    const classes = useStyles()
    const [attachments, setAttachments] = useState([])
    // const [file, setFile] = useState({ name: '' })
    // const [name, setName] = useState('')
    // const [showAddDialog, setShowAddDialog] = useState(false)
    const [keyword, setKeyword] = useState('')
    const { apiService, downloadFile } = useContext(StoreContext)    

    useEffect(() => {
        
        socket.emit('get-map-attachments', { map_id })        

        socket.on('map-attachments', data => {
            setAttachments(data.attachments)        
        })        

        return () => { socket.off('map-attachments') }
        
    },[map_id])

    // const openAddDialog = () => setShowAddDialog(true)
    // const closeAddDialog = () => setShowAddDialog(false)
    // const handleNameChange = (e) => setName(e.target.value)    
    // const handleFileChange = (e) => setFile(e.target.files[0])
    // const uploadFileHandler = async () => {
    //     if(!name || name.trim()==='') return false
    //     if(!file) return false
    //     const data = new FormData() 
    //     data.append('map_id',map_id)        
    //     data.append('layer_id',null)
    //     data.append('feature_id',null)
    //     data.append('name',name)
    //     data.append('file', file)
    //     const res = await apiService('/attachment','','post',data)
    //     if(res){
    //         closeAddDialog()
    //         setName('')
    //         setFile({ name: '' })            
    //         socket.emit('get-feature-attachments', { map_id })
    //     }
    // }

    const downloadFileHandler = useCallback(async filename => {
        await downloadFile(filename)
    },[downloadFile])

    const deleteAttachment = useCallback(async id => {
        if(window.confirm('Eliminare questo allegato?')){
            const res = await apiService(`/attachment/${id}`,'','delete')
            if(res){
                socket.emit('get-map-attachments', { map_id })
            }
        }        
    },[apiService,map_id])

    const renderAttachments = useMemo(() => {

        const filteredFiles = attachments.length===0 ? [] : attachments.filter(f => (
            f.name.toLowerCase().indexOf(keyword.toLowerCase())!==-1 ||  
            f.attachment_user.name.toLowerCase().indexOf(keyword.toLowerCase())!==-1 ||  
            f.attachment_user.surname.toLowerCase().indexOf(keyword.toLowerCase())!==-1
        ))        

        if(filteredFiles.length===0) return (
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={`Nessun allegato trovato per questo elemento`} secondary={'Usa il form in alto per caricare nuovi allegati'} />
                </ListItem>
            </List>
        )        
        const groupedFiles = _.groupBy(filteredFiles,'feature_id')        
        return <div className={classes.listContainer}>
            {Object.keys(groupedFiles).map((key,index) => {                                    
                const files = groupedFiles[key]
                const feature = files[0].attachment_feature
                return (
                    <List key={index} className={classes.list} subheader={(
                        <ListSubheader className={classes.listSubheader}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item>
                                    <img src={require(`@meetico/mappify/core/theme/images/map-icons/ico-${feature.type}.png`)} alt={`${feature.type} icon`} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.listFeatureTitle}>File/s for {feature.name ? feature.name : `${feature.type} #${feature.uuid}`} ({files.length})</Typography>
                                </Grid>
                            </Grid>
                        </ListSubheader>
                    )}>
                        {files.map((item,idx) => (
                            <FeatureFileListItem key={idx} divider={idx!==(files.length-1)} item={item} downloadFileHandler={downloadFileHandler} deleteAttachment={deleteAttachment} />
                        ))}                    
                    </List>
                )
            })}
        </div>
    }, [attachments, classes, keyword, downloadFileHandler,deleteAttachment])

    return (
        <Grid container className={classes.root}>
            <FeatureTitle is_generic section={'Files'} />
            <IconButton className={classes.closeIcon} onClick={onClosePanel}>
                <CloseIcon />
            </IconButton>
            {/*<Dialog open={showAddDialog} onClose={closeAddDialog} TransitionComponent={Transition} aria-labelledby="add-title" aria-describedby="add-description">
                <DialogTitle id="add-title">{"Add File"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ad-description">Fill the form below in order to add a new file</DialogContentText>
                    <FeatureFileForm file={file} name={name} handleFileChange={handleFileChange} handleNameChange={handleNameChange} uploadFileHandler={uploadFileHandler} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAddDialog} color="primary">Cancel</Button>
                    <Button onClick={uploadFileHandler} color="primary" endIcon={<UploadIcon />}>Upload File</Button>
                </DialogActions>
            </Dialog>*/}
            <Grid item xs={12} container direction="row" spacing={2} justify="flex-start" alignItems="center"> 
                <Grid item xs={12}>
                    <TextField 
                        className={classes.searchInput}
                        fullWidth
                        placeholder="Cerca per nome file o utente" 
                        onChange={e => setKeyword(e.target.value)} 
                        value={keyword}  
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>                                                            
                        }}                             
                        color="secondary"
                    />
                </Grid>
                {/*<Grid item xs={1} align="right">
                    <Tooltip placement="top" title="Add new file">
                        <IconButton edge="end" onClick={openAddDialog} className={classes.addIcon}>
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    </Grid>*/}
            </Grid>
            <Grid item xs={12} style={{marginTop: 10}}>
                {renderAttachments}
            </Grid>            
        </Grid>
    )
}

export default AttachmentsComponent