import { useReducer, useCallback } from 'react'
import { ENDPOINT, PROJECT_PREFIX } from '../config'
import axios from 'axios'
import { toast } from 'react-toastify';

const initState = {
    isAuth: false,
    isChecking: false,
    user: null,
    serverDown: false
}

const authReducer = (state, action) => {
    switch (action.type) {        
        case 'LOGIN':
            return { isAuth: true, user: action.user, isChecking: false, serverDown: false }
        case 'CHECK_USER_AUTHENTICATION':
            return { ...state, isChecking: true, serverDown: false }
        case 'LOGOUT':
            return initState
        case 'SERVER_DOWN':        
            return { ...state, isChecking:false, serverDown: true }
        default:
            throw new Error('Should not get here!')
    }
}

const useAuth = () => {
    const [authState, authDispatch] = useReducer(authReducer,initState)    

    const login = useCallback(async (credentials) => {
        try{
            const { data: { user, token} } = await axios.post(`${ENDPOINT}/login`,credentials)            
            localStorage.setItem(`${PROJECT_PREFIX}TOKEN`,token)
            localStorage.setItem(`${PROJECT_PREFIX}USR`, JSON.stringify(user))
            axios.defaults.headers.common['Authorization'] = token;            
            if(localStorage.getItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)) localStorage.removeItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)
            authDispatch({ type: 'LOGIN', user })
        }catch(error){            
            if(error.response){
                if(localStorage.getItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)) localStorage.removeItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)
                const message = error.response.data.message ? error.response.data.message : 'Errore durante l\'esecuzione dell\'operazione!'
                toast.error(message)   
            }else{                
                authDispatch({ type: 'SERVER_DOWN' })
                if(!localStorage.getItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)) toast.error("Impossibile collegarsi al server. Si prega di riprovare più tardi o di contattare l'assistenza.")
                localStorage.setItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`, '1')
            }            
        }
    },[])

    const signUp = useCallback(async (user) => {
        try{
            await axios.post(`${ENDPOINT}/sign-up`,{...user})                        
            toast.success('Operazione completata con successo')
            return true
        }catch(error){     
            console.dir(error.response.data)
            switch (error.response.data.code) {
                case 'EMAIL_EXISTS':                    
                    toast.error(error.response.data.message)    
                    break;
                case 'PHONE_EXISTS':                    
                    toast.error(error.response.data.message)    
                    break;
                default:
                    toast.error('Errore durante l\'esecuzione dell\'operazione!')    
                    break;
            }       
            return false            
        }
    },[])
    
    const logout = () => {        
        localStorage.removeItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)
        localStorage.removeItem(`${PROJECT_PREFIX}TOKEN`)
        localStorage.removeItem(`${PROJECT_PREFIX}USR`)
        axios.defaults.headers.common['Authorization'] = '';
        authDispatch({ type: 'LOGOUT' })
        window.location.href="/"
    }
    
    const chekUserToken = useCallback(async ()=> {
        try{
            authDispatch({ type: 'CHECK_USER_AUTHENTICATION' })
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)            
            if(!token) return authDispatch({ type: 'LOGOUT' })
            axios.defaults.headers.common['Authorization'] = token;
            await axios.get(`${ENDPOINT}/authorize`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))        
            if(localStorage.getItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)) localStorage.removeItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)
            authDispatch({ type: 'LOGIN', user })            
        }catch(error){
            console.dir(error)
            if(error.response){
                if(error.response.status===401){
                    logout()
                }else{
                    const message = error.response.data.message ? error.response.data.message : 'Errore durante l\'esecuzione dell\'operazione!'
                    toast.error(message)
                }                
            }else{                
                authDispatch({ type: 'SERVER_DOWN' })
                if(!localStorage.getItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`)) toast.error("Impossibile collegarsi al server. Si prega di riprovare più tardi o di contattare l'assistenza.")
                localStorage.setItem(`${PROJECT_PREFIX}SHOW_SRV_DWN_ALTR`, '1')
            }            
        }
    },[])

    return {
        isChecking: authState.isChecking,
        isAuth: authState.isAuth,
        user: authState.user,
        serverDown: authState.serverDown,
        login,
        logout,
        signUp,
        chekUserToken
    }
}

export default useAuth