import React, { useState, useEffect, useMemo } from 'react'
import { Map, ZoomControl, TileLayer, Marker, Rectangle, Circle, Polyline, Polygon, CircleMarker } from 'react-leaflet'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { socket } from '@meetico/mappify/layouts/map.layout'

const useStyles = makeStyles(theme => ({
    mapContainer: {
        width: '100%',
        height: 170,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 4
    }
}))

const InfoMap = ({ map_id, map, feature }) => {
    const classes = useStyles()
    const [mapCenter, setMapCenter] = useState()
    const [featureDetails, setFeatureDetails] = useState({})

    useEffect(() => {        
        socket.emit('get-feature-info', { map_id, feature_uuid: feature.uuid })        

        socket.on('feature-info-update', data => {
            if(data.feature_uuid===feature.uuid) setFeatureDetails(data)
        })

        return () => {
            socket.off('feature-info-update')
            socket.off('get-feature-info')
        }
    },[feature.uuid, map_id])

    const renderMapFeature = useMemo(() => {

        if(!feature || !featureDetails || !map) return ''

        const customMap = {...map}     
        const mapLayers = Object.values(customMap.mapRef.current.leafletElement._layers)        
        mapLayers.forEach(l => {
            if(l.options && l.options.id === feature.uuid){
                let center;
                if(feature.type==='circle' || feature.type==='marker' || feature.type==='circlemarker') center = l.getLatLng()
                else center = l.getCenter()
                
                setMapCenter(center)
                customMap.mapRef.current.leafletElement.panTo(center);
            }
        })
        switch(feature.type){
            case 'marker':                            
                return (
                    <Marker
                        id={feature.uuid}                        
                        position={feature.position}
                        zIndex={feature.zIndex}
                    >                                
                    </Marker>
                )
            case 'rectangle':
                return (
                    <Rectangle
                        id={feature.uuid}                        
                        bounds={feature.position}
                        color={featureDetails.color}
                        zIndex={feature.zIndex}                        
                    />
                )
            case 'circle':
                return (
                    <Circle
                        id={feature.uuid}                        
                        center={feature.position}
                        color={featureDetails.color}
                        fillColor={featureDetails.color}
                        radius={feature.radius}
                        zIndex={feature.zIndex}                        
                    />
                )
            case 'polyline':
                return (
                    <Polyline
                        id={feature.uuid}                        
                        positions={feature.position}
                        color={featureDetails.color}
                        zIndex={feature.zIndex}                        
                    />
                )
            case 'circlemarker':
                return (
                    <CircleMarker
                        id={feature.uuid}                        
                        center={feature.position}
                        color={featureDetails.color}
                        radius={feature.radius}
                        zIndex={feature.zIndex}                        
                    >
                    </CircleMarker>
                )
            case 'polygon':
                return (
                    <Polygon
                        id={feature.uuid}                        
                        positions={feature.position}
                        color={featureDetails.color}
                        zIndex={feature.zIndex}                        
                    />
                )
            default:
                return null
        }    
    }, [map, feature, featureDetails])

    return (
        <Grid container>
            <Grid item xs={12}>                                    
                <div className={classes.mapContainer}>
                    {mapCenter && (
                        <Map                         
                            id="info-map"
                            center={mapCenter}
                            zoom={13}
                            zoomControl={false}               
                            doubleClickZoom={false}
                            style={{ width: '100%', height: 170, borderRadius: 4 }}                        
                        >
                            <ZoomControl position="topright" />
                            {renderMapFeature}
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </Map>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

export default InfoMap