import React, { useState, useContext, useMemo } from 'react'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { validEmail } from '@meetico/mappify/core/utils'
import { StoreContext } from '@meetico/mappify/context/store.context'

const useStyles = makeStyles((theme) => ({
    inviteContainer: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background: '#f5f5f5',
        border: '1px solid #ccc'
    },
    inviteInputError:{
        border: '1px solid red'
    },
    inviteInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },    
    inviteButton:{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
        border: 'none'
    },
    inviteButtonLoader: {
        color: '#fff'
    }
}))

const ShareInviteForm = ({ map_id, fetchMapUsersAndInvitations }) => {
    const classes = useStyles()
    const { apiService, isLoadingData } = useContext(StoreContext)  
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)

    const handleEmailChange = async (e) => {        
        await setEmail(e.target.value)
        await setEmailError(false)        
    }

    const inviteUser = async () => {        
        if(!email || email.trim()==='' || !validEmail(email)){
            setEmailError(true)
            return false;
        }
        if(await apiService('/invitation','','post',{ email_invited: email, map_id })){
            setEmail('')
            fetchMapUsersAndInvitations()
        }
    }

    const renderButton = useMemo(() => {
        if(!isLoadingData) return 'Invita'
        return <CircularProgress size={20} className={classes.inviteButtonLoader} />        
    },[isLoadingData,classes.inviteButtonLoader])

    return (
        <Paper component="form" elevation={0} className={`${classes.inviteContainer} ${emailError?classes.inviteInputError:''}`}>
            <InputBase                
                type="email"
                value={email}
                onChange={handleEmailChange}
                className={classes.inviteInput}
                placeholder="Inserisci email"
                inputProps={{ 'aria-label': 'Inserisci email' }}
            />
            <Button className={classes.inviteButton} variant="contained" color="secondary" onClick={inviteUser}>
            {renderButton}
            </Button>
        </Paper>   
    )
}

export default ShareInviteForm