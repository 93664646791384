import React, { Fragment, useState } from 'react'
import { socket } from '../../../../layout/map.layout'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MapNoteListItem from '../../../lists/map-notes-list-item/map-notes-list-item'

const useStyles = makeStyles((theme) => ({
    addLayerFab:{
        width: 30,
        height: 30,
        minHeight: 30,
        boxShadow: 'none',
        color: '#fff'
    },
    container: {
        marginTop: 10
    },
    notesListContainer:{
        height: 'auto',        
        maxHeight: 185,
        overflowX: 'hidden',
        overflowY: 'auto',
        flexWrap: 'nowrap', 
        marginTop: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    },
    sectionTitle: { 
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    emptyText:{
        fontSize: 14,
        marginTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    emptySubtext:{
        fontSize: 12,
        marginTop: theme.spacing(1)
    },
    emptyAddButton:{
        marginTop: theme.spacing(2)
    }
}))

const NotesComponent = ({ map_id, user, notes, onToggleNote }) => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [form, setForm] = useState({ note: '' })    
    const [deleteNote, setDeleteNote] = useState()    

    const _onAddNote = () => {
        setOpenDialog(true)
    }

    const _onEditNote = note => {
        setForm({ note: note.note, uuid: note.uuid })
        setOpenDialog(true)
    }
    
    const _onDeleteNote = (note) => {
        setDeleteNote(note)
        setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false)
    }

    const handleInputChange = (name,value) => {        
        if(value.length<=140){
            setForm({
                ...form,
                [name]: value
            })
        }        
    }

    const deleteNoteHandler = () => {
        socket.emit('delete-note', { map_id, uuid: deleteNote.uuid })
        setOpenDeleteDialog(false)
    }

    const submitForm = async () => {        
        setOpenDialog(false)
        if(!form.uuid) socket.emit('new-note', { map_id, user_id: user.id, ...form })
        else socket.emit('update-note', { map_id, user_id: user.id, ...form })
        setForm({ note: '' })            
    }
    
    return (
        <Fragment>
            <Grid container direction="column" justify="flex-start" align="flex-start" spacing={2} className={classes.container}>
                <Grid item>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionTitle}>Manage Notes</Typography>                                    
                        </Grid>    
                        <Grid item>   
                            <Tooltip placement="top" title="Aggiungi nuovo wms">
                                <Fab color="secondary" aria-label="add" size="small" className={classes.addLayerFab} onClick={_onAddNote}>                                        
                                    <AddIcon />                                        
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item className={classes.notesListContainer}>
                    {(notes && notes.length>0) && notes.map(n => (
                        <MapNoteListItem 
                            key={n.uuid} 
                            note={n}          
                            _onToggleNote={onToggleNote}                  
                            _onEdit={_onEditNote}                                     
                            _onDelete={_onDeleteNote} 
                        />
                    ))}
                    {(notes && notes.length === 0) && (
                        <Fragment>
                            <Typography className={classes.emptyText} gutterBottom align="center">No notes added yet</Typography>
                            <Typography className={classes.emptySubtext} gutterBottom align="left">In order to add your first note, please click on the <b>"Add new note"</b> button</Typography>
                            <Button className={classes.emptyAddButton} fullWidth startIcon={<AddIcon />} variant="outlined" color="secondary" size="small" onClick={_onAddNote}>Add new note</Button>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
            <Dialog open={openDialog} fullWidth maxWidth={"xs"} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{!form.uuid ? 'Add new' : 'Edit'} note</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="note"
                                label="Note text"
                                type="text"
                                onChange={(e) => handleInputChange('note',e.target.value)}
                                value={form.note}
                                fullWidth
                                helperText={`Characters remaining: ${140 - form.note.length} / 140`}                                
                            />    
                        </Grid>                        
                    </Grid>                    
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={submitForm} color="primary">
                        {!form.uuid ? 'Add new' : 'Edit'} note
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm delete action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this note permanently?<br />
                        <small>This action cannot be undone</small>
                    </DialogContentText>                   
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDeleteDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={deleteNoteHandler} color="primary">
                        confirm and delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default NotesComponent