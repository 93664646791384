import React, { useState, Fragment, useContext } from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import DeleteIcon from '@material-ui/icons/Delete'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { StoreContext } from '@meetico/mappify/context/store.context'

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: 0
    },
    avatarContainer: {        
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    avatar:{
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '1rem'
    }
}))

const ShareUsersPendingList = ({ users = [], fetchInvitations }) => {
    const classes = useStyles()
    const { apiService } = useContext(StoreContext)  
    const [openConfirmDeleteDialog, setOpenConfirmDialog] = useState(false);
    const [deletedInvitation, setDeletedInvitation] = useState();
    const openConfirmDialog = invitation => {
        setOpenConfirmDialog(true)
        setDeletedInvitation(invitation)
    };

    const confirmDialogClose = () => {
        setOpenConfirmDialog(false)
        setDeletedInvitation(null)
    };

    const deleteInvitation = async () => {
        setOpenConfirmDialog(false)
        if(await apiService(`/invitation/${deletedInvitation.id}`,'','delete',{})) fetchInvitations()
    }

    return (
        <Fragment>
            <List dense>
                {users.length>0 ? users.map((invitation) => (
                    <Fragment key={invitation.id}>
                        <ListItem justify="center" alignItems="flex-start" className={classes.listItem}>
                            <ListItemAvatar className={classes.avatarContainer}>
                                <Avatar className={classes.avatar}>
                                    {invitation.receiver ? 
                                        `${invitation.receiver.name[0]}${invitation.receiver.surname[0]}`
                                    : (
                                        <PersonIcon />
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    invitation.receiver ? (
                                        <Typography variant="subtitle2">{`${invitation.receiver.name} ${invitation.receiver.surname}`}</Typography>
                                    ) : (
                                        <Typography variant="subtitle2">Utente non registrato</Typography>
                                    )
                                }
                                secondary={<Typography variant="caption">{invitation.email_invited}</Typography>}
                            />
                            <ListItemSecondaryAction>
                                <Tooltip placement="top" title="Rimanda Invito">
                                    <IconButton edge="end" aria-label="resend">
                                        <SendIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Rimuovi Invito">
                                    <IconButton edge="end" aria-label="remove" onClick={() => openConfirmDialog(invitation)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" />
                    </Fragment>
                )) : 
                <Typography>Non ci sono richieste di collaborazione in attesa per questa mappa</Typography>
            }
            </List>
            <Dialog
                open={openConfirmDeleteDialog}
                onClose={confirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Conferma Azione</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vuoi rimuovere questo invito permanentemente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmDialogClose} color="primary">
                        Annulla azione
                    </Button>
                    <Button onClick={deleteInvitation} color="secondary" autoFocus>
                        Conferma rimozione
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ShareUsersPendingList