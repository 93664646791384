import React, { useEffect, useState, useContext,useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import randomcolor from 'randomcolor'

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { StoreContext } from '@meetico/mappify/context/store.context'
import UserBadge from '@meetico/mappify/ui/badge/connected-user.badge'
import ConnectedUsersList from '@meetico/mappify/lists/connected-users-list/connected-users-list'
import { socket } from '@meetico/mappify/layouts/map.layout'

import '@meetico/mappify/core/styles/connected-user.css'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    userBadge:{
        border: 'none',
        cursor: 'pointer',        
    },
    avatar:{
        fontSize: 14,
        color: theme.palette.common.black
    },
    dialog: {
        width: '25%',
        maxWidth: 350,
    },
    dialogContentText:{
        outline: 'none',
        maxHeight:250,
        overflowY: 'scroll',
        padding: 0
    }
}));

const MAX_USERS = 3

const ConnectedUsers = ({ map_id, history }) => {
    const classes = useStyles()
    const { auth: { user } } = useContext(StoreContext)
    const [connectedUsers, setConnectedUsers] = useState([]);    
    const [openConnectedUsers, setOpenConnectedUsers] = useState(false)

    window.addEventListener("beforeunload", function (e) {
        socket.emit('unsubscribe', { map_id, user_id: user.id  })
    })

    const fetchConnectedUsers = useCallback(() => {
        socket.emit('subscribe', { map_id, user: { id: user.id, name: user.name, surname: user.surname } })               

        socket.on("connected-users", async data => {            
            const newConnectedUsers = await data.map(u => ({...u, background: randomcolor({luminosity: 'light'})}) )
            setConnectedUsers(newConnectedUsers);
        });

        socket.on("not-allowed", () => {            
            toast.error('Accesso negato - Per visualizzare la mappa devi essere invitato ed autorizzato')
            history.push('/')
        });
    },[map_id, user, history ])

    useEffect(() => {                      
        fetchConnectedUsers()
        return () => {
            socket.off("connected-users");
            socket.off("not-allowed");
        }
    }, [fetchConnectedUsers]);

    const handleConnectedUsers = () => setOpenConnectedUsers(true)
    const closeConnectedUsers = () => setOpenConnectedUsers(false)    

    return (
        <div className={classes.root}>            
            {connectedUsers.length>0 && (
                <AvatarGroup max={MAX_USERS} onClick={handleConnectedUsers}>
                    {connectedUsers.map(({ name, surname, id, background }) => {
                        return (
                            <Tooltip key={id} placement={'top'} title={`${name} ${surname}`}>
                                <UserBadge                                    
                                    className={classes.userBadge}
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                >
                                    <Avatar style={{backgroundColor: background }} className={classes.avatar}>{`${name[0]}${surname[0]}`}</Avatar>
                                </UserBadge>
                            </Tooltip>
                        )
                    })}
                </AvatarGroup>
            )}
            <Dialog            
                open={openConnectedUsers}
                onClose={closeConnectedUsers}
                PaperProps={{ className: classes.dialog }}
            >
                <DialogTitle>Chi sta partecipando</DialogTitle>
                <DialogContent dividers={true} className={classes.dialogContentText}>
                    <Grid container direction="column">                        
                        <Grid item className={classes.listContainer}>                            
                            <ConnectedUsersList users={connectedUsers} />                    
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConnectedUsers} variant="contained" color="primary">
                        Chiudi
                    </Button>
                </DialogActions>
        </Dialog>
        </div>
    )
}

export default withRouter(ConnectedUsers)