import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    searchInput: {
        background: '#F1F1F1',
        borderRadius: 5,
        border:'none',
        padding: '5px 10px',
        fontSize: 13,
        color: theme.palette.common.black,                
    },
    searchIcon: {
        width: 19,
        height: 19
    },
    inputPlaceholder:{        
        '&::placeholder': {            
            opacity: 1,
            color: theme.palette.common.black,
        }
    }
}))

const MapLayerControlSearch = ({ keyword, onSearch }) => {
    const classes = useStyles()
    return (
        <InputBase
            fullWidth
            className={classes.searchInput}
            placeholder="Search for a map element"            
            color={'primary'}
            value={keyword}
            onChange={onSearch}
            classes={{input: classes.inputPlaceholder}}
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
            }
        />
    )
}

export default MapLayerControlSearch