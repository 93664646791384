import React from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    gridContainer:{
        position: 'relative',
        minHeight: '100vh',    
    },
    absoluteBar: {
        background: 'transparent',
        boxShadow: 'none'
    },
    stickyBar: {
        backgroundColor: '#474B57',
        marginBottom: '2rem'
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    body: {
        marginTop: 'auto',
        paddingTop: '64px',
        marginBottom: 'auto'
    }
}));

const ClearLayout = ({children, toolbarPosition, history}) => {
    const classes = useStyles();
    return (
        <Grid container direction={"column"} className={classes.gridContainer}>
            <Grid item>
                <AppBar position={toolbarPosition}
                        className={toolbarPosition === 'absolute' ? classes.absoluteBar : classes.stickyBar}>
                    <Toolbar>
                        <Button onClick={() => history.push('/')} color={"inherit"} className={classes.logo}>
                            <Typography variant="h5" gutterBottom>Mappify</Typography>
                        </Button>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item className={classes.body}>
                {children}
            </Grid>
        </Grid>
    );
};

ClearLayout.defaultProps = {
    toolbarPosition: 'sticky'
};

ClearLayout.propTypes = {
    toolbarPosition: PropTypes.string
};


export default withRouter(ClearLayout);
