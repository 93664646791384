import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ShareInviteForm from '@meetico/mappify/forms/share-invite.form'
import ShareUsersList from '@meetico/mappify/lists/share-users/share-users.list'
import ShareUsersPendingList from '@meetico/mappify/lists/share-users-pending/share-users-pending.list'
import { StoreContext } from '@meetico/mappify/context/store.context'

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '40%',
        maxWidth: 650,
    },
    dialogContentText:{
        outline: 'none'
    },    
    listContainer:{
        marginTop: theme.spacing(2),        
    },
    tabLabel:{
        textTransform: 'initial'
    },
    tabPanel: {
        maxHeight: 350,
        minHeight: 200,
        height: 'auto',
        overflowY: 'scroll',
    }    
}));

const ShareDialog = ({ map, open, closeDialog }) => {    
    const classes = useStyles()
    const { auth: { isAuth, user }, apiService, dataset } = useContext(StoreContext)
    const [invitations, setInvitations] = useState([])
    const [users, setUsers] = useState([])
    const [currentTab, setCurrentTab] = useState(0)
    
    const fetchMapUsersAndInvitations = useCallback(() => {
        apiService(`/map-invitations/${map.id}`,`map_invitations_${map.id}`,'get')
        apiService(`/map-users/${map.id}`,`map_users_${map.id}`,'get')
    },[apiService,map.id])

    useEffect(() => {        
        if(isAuth && !dataset[`map_invitations_${map.id}`] ) fetchMapUsersAndInvitations()
        if(isAuth && dataset[`map_invitations_${map.id}`] && (invitations!==dataset[`map_invitations_${map.id}`].map_invitations)) setInvitations(dataset[`map_invitations_${map.id}`].map_invitations)
        if(isAuth && dataset[`map_users_${map.id}`] && (users!==dataset[`map_users_${map.id}`].map_users)) setUsers(dataset[`map_users_${map.id}`].map_users)
    },[isAuth, dataset, map.id, invitations, users, fetchMapUsersAndInvitations])    

    const handleTabChange = (event, tab) => {        
        setCurrentTab(tab);        
    }

    const renderMapUsers = useMemo(() => { 
        return <ShareUsersList users={users} map={map} currentUser={user} fetchUsers={fetchMapUsersAndInvitations} />
    }, [users,fetchMapUsersAndInvitations,map,user])

    const renderMapInvitations = useMemo(() => {
        return <ShareUsersPendingList users={invitations} fetchInvitations={fetchMapUsersAndInvitations} />
    }, [invitations,fetchMapUsersAndInvitations])

    return (
        <Dialog            
            open={open}
            onClose={closeDialog}
            PaperProps={{ className: classes.dialog }}
        >
            <DialogTitle>Impostazione di condivisione</DialogTitle>
            <DialogContent dividers={true} className={classes.dialogContentText}>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>Invita collaboratore</Typography>
                        <ShareInviteForm map_id={map.id} fetchMapUsersAndInvitations={fetchMapUsersAndInvitations} />
                    </Grid>
                    <Grid item className={classes.listContainer}>                            
                        <Tabs value={currentTab} onChange={handleTabChange} aria-label="share tab">
                            <Tab className={classes.tabLabel} label={<Typography variant="h6">Chi ha accesso ({users.length })</Typography>} id="tab-0" aria-controls="tab-0" />
                            <Tab className={classes.tabLabel} label={<Typography variant="h6">In attesa ({invitations.length})</Typography>} id="tab-1" aria-controls="tab-1" />
                        </Tabs>                            
                        <div role="tabpanel" id="tab-0" aria-controls="tab-0" hidden={currentTab!==0} className={classes.tabPanel}>
                            {renderMapUsers}
                        </div>
                        <div role="tabpanel" id="tab-1" aria-controls="tab-1" hidden={currentTab!==1} className={classes.tabPanel}>
                            {renderMapInvitations}
                        </div>                            
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} variant="contained" color="primary">
                    Fatto
                </Button>
            </DialogActions>
      </Dialog>
    )
}

export default ShareDialog