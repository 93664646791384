import React, { useState, useMemo, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoIcon from "@material-ui/icons/Info";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ForumIcon from "@material-ui/icons/Forum";

import InfoComponent from "@meetico/mappify/map/feature-content/info/info.component";
import CommentsComponent from "@meetico/mappify/map/feature-content/comments/comments.component";
import AttachmentsComponent from "@meetico/mappify/map/feature-content/attachments/attachments.component";
import TabPanel from "@meetico/mappify/ui/tab-panel/tab-panel.component";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        width: "50vw",
        padding: `0.8rem 1.5rem`,
    },
    tab: {
        color: "#fff",
    },
    tabs: {
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
    },
}));

const FeatureContentComponent = ({ map, feature, map_id, onClosePanel }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderTabComponent = useMemo(() => {
        return (
            <Fragment>
                <TabPanel value={value} index={0}>
                    {value === 0 && (
                        <InfoComponent
                            map={map}
                            map_id={map_id}
                            feature={feature}
                            onClosePanel={onClosePanel}
                        />
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {value === 1 && (
                        <CommentsComponent
                            map_id={map_id}
                            feature={feature}
                            onClosePanel={onClosePanel}
                        />
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {value === 2 && (
                        <AttachmentsComponent
                            map_id={map_id}
                            feature={feature}
                            onClosePanel={onClosePanel}
                        />
                    )}
                </TabPanel>
            </Fragment>
        );
    }, [value, map_id, feature, onClosePanel, map]);

    return (
        <Paper square className={classes.root}>
            {renderTabComponent}
            <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="feature content tabs"
                centered
            >
                <Tab className={classes.tab} icon={<InfoIcon />} />
                <Tab className={classes.tab} icon={<ForumIcon />} />
                <Tab className={classes.tab} icon={<NoteAddIcon />} />
            </Tabs>
        </Paper>
    );
};

export default FeatureContentComponent;
