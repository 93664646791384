import React, { Fragment } from 'react'
import socketIOClient from "socket.io-client";
import {withRouter} from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import MapToolbarComponent from "../components/ui/toolbar/map.component"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import bgPattern from '../theme/images/pattern-bg-gestionale.jpg'

export const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}/maps`);
const useStyles = makeStyles(theme => ({
    gridContainer:{
        position: 'relative',
        minHeight: '100vh',
        backgroundImage: `url(${bgPattern})`        
    }
}))

const MapLayout = (props) => {
    const { children, toolbarPosition } = props    
    const classes = useStyles()
    return (
        <Fragment>
            <Grid container justify="flex-start" className={classes.gridContainer} spacing={0}>
                <Grid item xs={12}>                    
                    <MapToolbarComponent position={toolbarPosition} />
                </Grid>                            
            </Grid>            
            {children}
        </Fragment>
    )
}

MapLayout.defaultProps = {
    toolbarPosition: 'absolute'
}

MapLayout.propTypes = {
    toolbarPosition: PropTypes.string
}

export default withRouter(MapLayout)
