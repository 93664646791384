import React from 'react'

import useAuth from '../reducers/auth.reducer'
import useApi from '../reducers/api.reducer'

export const StoreContext = React.createContext()

const StoreContextProvider = props => {
    const {dataset,isFetchingData,isLoadingData, apiService,downloadFile, clearDataset} = useApi()
     
    const store = {
        auth: useAuth(),
        apiService,
        downloadFile,
        isFetchingData,
        isLoadingData,
        clearDataset,
        dataset
    }
    
    return (
        <StoreContext.Provider value={store}>
            {props.children}            
        </StoreContext.Provider>
    )
}

export default StoreContextProvider