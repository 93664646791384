import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    tab: {        
        minWidth: 85,
        fontWeight: 400,
        minHeight: 20,
        padding: `0px 12px`,
        lineHeight: 1,
        color: `${theme.palette.common.black} !important`,
    },
    tabSelected:{
        fontWeight: 400,
        color: `${theme.palette.common.black} !important`,
    },
    tabIcon: {
        color: `${theme.palette.common.black} !important`
    }
}))

const TabTitle = ({ toggleVisibility, visibility, tabTitle }) => {
    const classes = useStyles()
    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
                <Tooltip placement="top" title="Click to toggle view of all elements">
                    <div size="small" onClick={toggleVisibility} className={classes.tabIcon}>
                        {visibility ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
                    </div>
                </Tooltip>
            </Grid>                                        
            <Grid item>{tabTitle}</Grid>
        </Grid>        
    )
}

export default TabTitle