import React, { Fragment, useState, useContext } from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PortraitIcon from '@material-ui/icons/Portrait'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { StoreContext } from '@meetico/mappify/context/store.context'

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: 0
    },
    avatarContainer: {        
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    avatar:{
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '1rem'
    }
}))

const ShareUserList = ({ users = [], currentUser, fetchUsers, map }) => {
    const classes = useStyles()   
    const { apiService } = useContext(StoreContext)  
    const [openConfirmDeleteDialog, setOpenConfirmDialog] = useState(false);
    const [deletedCollaborator, setDeletedCollaborator] = useState();
    
    const openConfirmDialog = user => {
        setOpenConfirmDialog(true)
        setDeletedCollaborator(user)
    };

    const confirmDialogClose = () => {
        setOpenConfirmDialog(false)
        setDeletedCollaborator(null)
    };

    const removeUserFromMap = async () => {
        setOpenConfirmDialog(false)
        if(await apiService(`/remove-user-from-map`,'','put',{ map_id:map.id, user_id: deletedCollaborator.id})) fetchUsers()
    } 
    return (
        <Fragment>
            <List dense>
                {users.length>0 ? users.map((user) => {                    
                    return (
                        <Fragment key={user.id}>
                            <ListItem justify="center" alignItems="flex-start" className={classes.listItem}>
                                <ListItemAvatar className={classes.avatarContainer}>
                                    <Avatar className={classes.avatar}>{`${user.name[0]}${user.surname[0]}`}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                    <Typography variant="subtitle2">{`${user.name} ${user.surname}`}</Typography>                               
                                    }
                                    secondary={
                                        <Typography variant="caption">{user.email}</Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {user.id !== map.owner.id ? (
                                        <Fragment>
                                            {user.id!==currentUser.id ? (
                                                <Tooltip placement="top" title="Rimuovi collaboratore">
                                                    <IconButton edge="end" aria-label="delete" onClick={() => openConfirmDialog(user)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : null}   
                                        </Fragment>                                     
                                    ) : (
                                        <Tooltip placement="top" title="Sei il proprietario">
                                            <IconButton edge="end" aria-label="owner">
                                                <PortraitIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}                            
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </Fragment>
                    
                )}) : (
                    <Typography>Non ci sono collaboratori per questa mappa</Typography>
                )                
            }
            </List>
            <Dialog
                open={openConfirmDeleteDialog}
                onClose={confirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Conferma Azione</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vuoi davvero rimuovere questo collaboratore dalla mappa?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmDialogClose} color="primary">
                        Annulla azione
                    </Button>
                    <Button onClick={removeUserFromMap} color="secondary" autoFocus>
                        Conferma rimozione
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>        
    )
}

export default ShareUserList