import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '@meetico/mappify/core/theme/images/logo.svg'
import { StoreContext } from '@meetico/mappify/context/store.context.js'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem 1.5rem'
    },    
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }    
}));

const Login = () => {

    const classes = useStyles();
    const {auth: { login } } = useContext(StoreContext)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    
    const loginHandler = (e) => {
        e.preventDefault();
        login({email, password})
    }

    const handleFormSubmit = () => {
        return !email || !password
    }

    return (
        <Container component="main" maxWidth="xs">        
            <Paper className={classes.paper}>
                <img src={Logo} width="35" alt="logo" />
                <Typography component="h1" variant="h5">
                    Accedi a Mappify
                </Typography>
                <form className={classes.form} noValidate onSubmit={loginHandler}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        disabled={handleFormSubmit()}
                    >
                        Accedi
                    </Button>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Link href="/sign-up" variant="caption">
                                Registrati
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/reset-password" variant="caption">
                                Non ricordi la password?
                            </Link>
                        </Grid>                        
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Typography variant="body2" className={classes.bottomText} align="center">
                    {`Mappify - Copyright © Meetico LTD ${new Date().getFullYear()}.`}
                </Typography>
            </Box>
        </Container>
    );
}

export default Login