import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'

import { makeStyles } from '@material-ui/core/styles'
import { socket } from '@meetico/mappify/layouts/map.layout'
import { StoreContext } from '@meetico/mappify/context/store.context'
import bgPattern from '@meetico/mappify/core/theme/images/bg-chat.jpeg'

import FeatureTitle from '@meetico/mappify/map/feature-content/feature-title/feature-title.component'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%',
        marginTop: theme.spacing(3)
    },
    messageBox: {
        width: '100%',
        background: '#f5f5f5',
        border: 'none',
        padding: theme.spacing(2),
        resize: 'none',
        borderRadius: 20,
        outline: 'none',
        fontFamily: 'Rubik'
    },
    messageButton:{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover':{
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    },
    closeIcon:{
        position: 'absolute',
        top: 0,
        right: 0
    },
    commentsContainer:{
        height: 'calc(100% - 50px)'        
    },
    chatEmptyMessage: {
        textAlign: 'center',
        fontSize: 14,
        color: theme.palette.grey[600]
    },
    messagesContainer: {        
        height: 'calc(90vh - 110px)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        boxSizing: 'border-box',
        backgroundImage: `url(${bgPattern})`,
        borderRadius: 4,
        border: '1px solid #ccc'
    },
    messagesScrollable: {        
        maxHeight: '100%', 
        overflowY: 'scroll',        
        padding: theme.spacing(2),
        marginTop: 0
    },
    messageRight:{
        float: 'right',
        width: 'auto',  
        maxWidth: '85%',
        background: '#DCF8C6',
        borderRadius: 10,
        whiteSpace: 'pre-wrap'
    },
    messageLeft:{
        float: 'left',     
        width: 'auto',
        maxWidth: '85%',
        borderRadius: 10,
        whiteSpace: 'pre-wrap'
    },
    commentUser:{
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    commentDatetime: {
        width: '100%',
        textAlign: 'right',
        fontSize: 10,
        color: theme.palette.grey[700]
    },        
}))

const CommentsComponent = ({ map_id, onClosePanel }) => {
    const classes = useStyles()
    const { auth: { user } } = useContext(StoreContext)
    const [message, setMessage] = useState('')
    const [comments, setComments] = useState([])        

    useEffect(() => {
        
        socket.emit('get-map-comments', { map_id })        

        socket.on('map-comments', data => {                        
            setComments(data.comments)        
        })        

        return () => { socket.off('map-comments') }
        
    },[map_id])            

    const handleMessageChange = (e) => {        
        setMessage(e.target.value)
    }

    const handleSubmitComment = () => {
        if(!message || message.trim()==='') return false
        const comment = {map_id, user_id: user.id, message }        
        socket.emit('new-map-comment', comment)
        setMessage('')
    }

    const renderComments = useMemo(() => {
        if(comments.length>0){            
            return comments.map(comment => (
                <Grid item xs={12} key={comment.uuid}>
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Paper className={(comment.comment_user && comment.comment_user.id === user.id) ? classes.messageRight : classes.messageLeft}>
                                <Box px={1} py={0}>
                                    <Typography className={classes.commentUser} component="p">{!comment.comment_user ? 'Utente sconosciuto' : `${comment.comment_user.name} ${comment.comment_user.surname}`}</Typography>
                                    <Typography variant="body2">{comment.message}</Typography>                                                    
                                    <Typography className={classes.commentDatetime} component="p" align={(comment.comment_user && comment.comment_user.id === user.id) ? 'right' : 'left'} variant="overline">{moment(comment.date_of_creation).format('DD MMM - HH:mm')}</Typography>
                                </Box>
                            </Paper>                            
                        </Grid>                        
                    </Grid>
                </Grid>
            ))            
        }
        if(comments.length===0){
            return (
                <Grid item xs={12}>
                    <Typography className={classes.chatEmptyMessage}>No messages available for this chat yet</Typography>
                </Grid>
            )
        }        
    },[comments,classes,user])

    useEffect(() => {
        document.getElementById("messages-container").scrollTop=document.getElementById("messages-container").scrollHeight
    },[comments])

    return ( 
        <Fragment>
            <FeatureTitle is_generic section={'Chat'} />
            <IconButton className={classes.closeIcon} onClick={onClosePanel}>
                <CloseIcon />
            </IconButton>
            <Grid container direction="row" justify="space-between">
                <Grid item xs={12} className={classes.messagesContainer}>
                    <Grid container direction="row" id="messages-container" className={classes.messagesScrollable} spacing={2}>
                        {renderComments}                        
                    </Grid>
                </Grid>                                                
                <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={11}>
                            <TextareaAutosize
                                rowsMax={3}
                                className={classes.messageBox}
                                aria-label="comment-box"
                                placeholder="Write your message here"                                
                                onChange={handleMessageChange}
                                value={message}
                            />                            
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton className={classes.messageButton} edge="start" onClick={handleSubmitComment}>
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
        </Fragment>       
    )
}

export default CommentsComponent