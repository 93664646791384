import React, { Fragment } from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingVertical: theme.spacing(1) 
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    avatar:{
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '1rem'
    }
}))

const ShareUserList = ({ users = [] }) => {
    const classes = useStyles()    
    return (
        <List>
            {users.length>0 ? users.map((user,index) => {                    
                return (
                    <Fragment key={user.id}>
                        <ListItem justify="flex-start" alignItems="center" className={classes.listItem}>
                            <ListItemAvatar className={classes.avatarContainer}>
                                <Avatar className={classes.avatar}>{`${user.name[0]}${user.surname[0]}`}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                <Typography variant="subtitle2">{`${user.name}${user.surname}`}</Typography>                               
                                }                                    
                            />                                
                        </ListItem>
                        {index!==(users.length-1) ? <Divider component="li" /> : null}
                    </Fragment>                    
                )}) : null                
            }
        </List>        
    )
}

export default ShareUserList