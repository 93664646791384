import React, { useContext } from 'react'
import {withRouter} from "react-router-dom"
import { StoreContext } from '@meetico/mappify/context/store.context'

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"

import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
    menu: {
        '& .MuiMenu-paper': {
            minWidth: 250,
            borderRadius: 0,
            padding: 0,
            '& .MuiListItem-button': {
                borderBottom: '1px solid rgba(0,0,0,.12)',
                padding: '1rem'
            }
        }
    },
}))

const UserDropdown = ({ anchorEl, handleClose, history }) => {
    const {auth: { user, logout } } = useContext(StoreContext)
    const classes = useStyles()
    return (
        <Menu
            id="toolbar-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted variant={"menu"}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={Boolean(anchorEl)} className={classes.menu} onClose={handleClose}
        >       
            {user && (
                <MenuItem>
                    <Typography>{`${user.name} ${user.surname}`}</Typography>
                </MenuItem>
            )}
            <MenuItem onClick={()=> { logout(); history.push('/')}} style={{borderBottom: "none"}}>
                <Icon color={"error"} style={{marginRight: '1rem'}}>power_settings_new</Icon>
                <Typography>logout</Typography>
            </MenuItem>
        </Menu>
    )
}

export default withRouter(UserDropdown)