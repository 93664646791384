import React from 'react'
import { FeatureGroup, Tooltip, Marker, Rectangle, Circle, Polyline, Polygon, CircleMarker} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import './features.css'
const MapFeatures = ({ onCreated, onEdited, onDeleted, onFeatureClick, layerRef, layer, features, selectedLayer, selectedFeature, disableFeatureClik, enableFeatureClik }) => {

    return (
        <FeatureGroup ref={layerRef}>

            {(selectedLayer && selectedLayer.uuid===layer.uuid) && (
                <EditControl
                    position='bottomright'
                    onEdited={(e) => onEdited(e,layer)}
                    onCreated={(e) => onCreated(e,layer)}
                    onDeleted={(e) => onDeleted(e,layer)}
                    onEditStart={disableFeatureClik}
                    onEditStop={enableFeatureClik}
                    onDeleteStart={disableFeatureClik}
                    onDeleteStop={enableFeatureClik}

                />
            )}

            {features && features.map((feature, index) => {
                if(feature){          
                    const featureDashArray = (selectedFeature && selectedFeature.uuid===feature.uuid) ? "10px" : null
                    switch(feature.type){
                        case 'marker':                            
                            return (
                                <Marker
                                    draggable
                                    id={feature.uuid}
                                    key={index}
                                    position={feature.position}
                                    zIndex={feature.zIndex}                                            
                                    onClick={() => onFeatureClick(feature,layer)}                                    
                                > 
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Marker ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </Marker>
                            )
                        case 'rectangle':
                            return (
                                <Rectangle
                                    id={feature.uuid}
                                    key={index}
                                    bounds={feature.position}
                                    color={feature.color}
                                    zIndex={feature.zIndex}  
                                    dashArray={featureDashArray}                                  
                                    onClick={() => onFeatureClick(feature,layer)}
                                >
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Reactangle ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </Rectangle>
                            )
                        case 'circle':
                            return (
                                <Circle
                                    id={feature.uuid}
                                    key={index}
                                    center={feature.position}
                                    color={feature.color}
                                    fillColor={feature.color}
                                    radius={feature.radius}
                                    zIndex={feature.zIndex}
                                    dashArray={featureDashArray}
                                    onClick={() => onFeatureClick(feature,layer)}
                                >
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Circle ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </Circle>
                            )
                        case 'polyline':
                            return (
                                <Polyline
                                    id={feature.uuid}
                                    key={index}
                                    positions={feature.position}
                                    color={feature.color}
                                    zIndex={feature.zIndex}
                                    dashArray={featureDashArray}
                                    onClick={() => onFeatureClick(feature,layer)}
                                >
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Polyline ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </Polyline>
                            )
                        case 'circlemarker':
                            return (
                                <CircleMarker
                                    id={feature.uuid}
                                    key={index}
                                    center={feature.position}
                                    color={feature.color}
                                    radius={feature.radius}
                                    zIndex={feature.zIndex}
                                    dashArray={featureDashArray}
                                    onClick={() => onFeatureClick(feature,layer)}
                                >
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Circle Marker ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </CircleMarker>
                            )
                        case 'polygon':
                            return (
                                <Polygon
                                    id={feature.uuid}
                                    key={index}
                                    positions={feature.position}
                                    color={feature.color}
                                    zIndex={feature.zIndex}
                                    dashArray={featureDashArray}
                                    onClick={() => onFeatureClick(feature,layer)}
                                >
                                    <Tooltip>
                                        <b>{feature.name ? feature.name : `Polygon ID ${feature.uuid}`}</b>
                                        {feature.name && <small><br />{`ID: ${feature.uuid}`}</small>}
                                        <br />
                                        <small><i>{`Layer: ${layer.name}`}</i></small>
                                    </Tooltip>
                                </Polygon>
                            )
                        default:
                            return null
                    }
                }
                return feature
            })}

        </FeatureGroup>
    )
}

export default MapFeatures