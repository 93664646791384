import React, { Fragment, useState } from 'react'
import { socket } from '../../../../layout/map.layout'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MapLayerListItem from '../../../lists/map-layer-list-item/map-layer-list-item'

const useStyles = makeStyles((theme) => ({
    addLayerFab:{
        width: 30,
        height: 30,
        minHeight: 30,
        boxShadow: 'none',
        color: '#fff'
    },
    container: {
        marginTop: 10
    },
    layersListContainer: {
        height: 'auto',
        maxHeight: 185,        
        overflowX: 'hidden',
        overflowY: 'auto',
        flexWrap: 'nowrap', 
        marginTop: theme.spacing(2),        
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.7)'
        }
    },
    sectionTitle: { 
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    emptyText:{
        fontSize: 14,
        marginTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    emptySubtext:{
        fontSize: 12,
        marginTop: theme.spacing(1)
    },
    emptyAddButton:{
        marginTop: theme.spacing(2)
    }
}))

const LevelsComponent = ({ map_id, user, layers, selectedLayer, onSetLayerSelected, onSetLayerShow, onSetLayerHide }) => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [form, setForm] = useState({ name: '' })
    const [deleteLayer, setDeleteLayer] = useState()    

    const _onAddLayer = () => {
        setOpenDialog(true)
    }

    const _onEditLayer = layer => {
        setForm({ name: layer.name, uuid: layer.uuid })
        setOpenDialog(true)
    }
    
    const _onDeleteLayer = (layer) => {
        setDeleteLayer(layer)
        setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false)
    }

    const _setLayerShow = (uuid) => {
        onSetLayerShow(uuid)
    }

    const _setLayerHide = (uuid) => {
        onSetLayerHide(uuid)
    }

    const _onSetLayerSelected = (layer) => {
        onSetLayerSelected(layer)
    }

    const handleInputChange = (name,value) => {        
        setForm({
            ...form,
            [name]: value
        })
    }

    const deleteLayerHandler = () => {
        socket.emit('delete-layer', { map_id, uuid: deleteLayer.uuid })
        setOpenDeleteDialog(false)
    }

    const submitLayerForm = () => {
        setOpenDialog(false)
        if(!form.uuid) socket.emit('new-layer', { map_id, user_id: user.id, ...form })
        else socket.emit('update-layer', { map_id, user_id: user.id, ...form })
        setForm({ name: '' })
    }
    
    return (
        <Fragment>
            <Grid container direction="column" justify="flex-start" align="flex-start" spacing={2} className={classes.container}>
                <Grid item>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionTitle}>Manage Layers</Typography>                                    
                        </Grid>    
                        <Grid item>   
                            <Tooltip placement="top" title="Add new layer">
                                <Fab color="secondary" aria-label="add" size="small" className={classes.addLayerFab} onClick={_onAddLayer}>                                        
                                    <AddIcon />                                        
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item className={classes.layersListContainer}>
                    {(layers && layers.length>0) && layers.map(layer => (
                        <MapLayerListItem 
                            key={layer.uuid} 
                            layer={layer} 
                            selectedLayer={selectedLayer}
                            _onSetLayerSelected={_onSetLayerSelected} 
                            _setLayerShow={_setLayerShow}
                            _setLayerHide={_setLayerHide}
                            _onEditLayer={_onEditLayer}                                     
                            _onDeleteLayer={_onDeleteLayer} 
                        />
                    ))}
                    {(layers && layers.length === 0) && (
                        <Fragment>
                            <Typography className={classes.emptyText} gutterBottom align="center">No layers added yet</Typography>
                            <Typography className={classes.emptySubtext} gutterBottom align="left">In order to add your first layer, please click on the "Add new layer" button</Typography>
                            <Button className={classes.emptyAddButton} fullWidth startIcon={<AddIcon />} variant="outlined" color="secondary" size="small" onClick={_onAddLayer}>Add new layer</Button>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{!form.uuid ? 'Add new' : 'Edit'} layer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please {!form.uuid ? 'provide' : 'edit'} the layer name and click on the "{!form.uuid ? 'Add' : 'Edit'} layer" button.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nome Layer"
                        type="text"
                        onChange={(e) => handleInputChange('name',e.target.value)}
                        value={form.name}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={submitLayerForm} color="primary">
                        {!form.uuid ? 'Add' : 'Edit'} layer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm delete action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this layer permanently?<br />
                        <small>This action cannot be undone</small>
                    </DialogContentText>                   
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDeleteDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={deleteLayerHandler} color="primary">
                        confirm and delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default LevelsComponent