import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react'
import { StoreContext } from '@meetico/mappify/context/store.context'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import UploadIcon from '@material-ui/icons/CloudUpload'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

import { makeStyles } from '@material-ui/core/styles'
import { socket } from '@meetico/mappify/layouts/map.layout'


import FeatureTitle from '@meetico/mappify/map/feature-content/feature-title/feature-title.component'
import FeatureFileForm from '@meetico/mappify/forms/feature-file.form'
import FeatureFileListItem from '@meetico/mappify/lists/feature-file-item/feature-file-item.list'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%',
        padding: theme.spacing(1)
    },
    closeIcon:{
        position: 'absolute',
        top: 0,
        right: 0
    },
    addIcon:{
        padding:0,
        color: theme.palette.common.black
    }, 
    searchInput:{
        marginTop: theme.spacing(2)
    },
    listContainer:{
        maxHeight: '90vh', 
        overflowY: 'scroll',        
        // padding: theme.spacing(2),        
        margin: 0,
        marginTop: 15,
        width: '100%'
    },
    listItem:{
        padding: '8px 16px 8px 0',
        width: '100%',        
    },
    attachmentAvatar: {
        border: '1px solid #ddd'
    },
    attachmentSubtitle: {
        color: "#666666"
    }
}))

const AttachmentsComponent = ({ map_id, is_generic = false, feature, onClosePanel }) => {
    const classes = useStyles()
    const [attachments, setAttachments] = useState([])
    const [file, setFile] = useState({ name: '' })
    const [name, setName] = useState('')
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [keyword, setKeyword] = useState('')
    const { apiService, downloadFile } = useContext(StoreContext)    

    useEffect(() => {
        if(!is_generic){
            socket.emit('get-feature-attachments', { map_id, feature_id: feature.id })        

            socket.on('feature-attachments', data => {
                if(data.feature_id===feature.id) setAttachments(data.attachments)        
            })
        }else{
            socket.emit('get-map-attachments', { map_id })        

            socket.on('map-attachments', data => {
                console.log('map-attachments', data)
                setAttachments(data.attachments)        
            })
        }

        return () => {
            if(!is_generic) socket.off('feature-attachments')
            else socket.off('map-attachments')
        }
        
    },[map_id, feature, is_generic])

    const openAddDialog = () => setShowAddDialog(true)
    const closeAddDialog = () => setShowAddDialog(false)
    const handleNameChange = (e) => setName(e.target.value)    
    const handleFileChange = (e) => setFile(e.target.files[0])
    const uploadFileHandler = async () => {
        if(!name || name.trim()==='') return false
        if(!file) return false
        const data = new FormData() 
        data.append('map_id',map_id)        
        data.append('layer_id',feature.layer_id)
        data.append('feature_id',feature.id)
        data.append('name',name)
        data.append('file', file)
        const res = await apiService('/attachment','','post',data)
        if(res){
            closeAddDialog()
            setName('')
            setFile({ name: '' })            
            socket.emit('get-feature-attachments', { map_id, feature_id: feature.id })
        }
    }

    const downloadFileHandler = useCallback(async filename => {
        await downloadFile(filename)
    },[downloadFile])

    const deleteAttachment = useCallback(async id => {
        if(window.confirm('Eliminare questo allegato?')){
            const res = await apiService(`/attachment/${id}`,'','delete')
            if(res){
                socket.emit('get-feature-attachments', { map_id, feature_id: feature.id })
            }
        }        
    },[apiService,feature,map_id])

    const renderAttachments = useMemo(() => {
        return (
            <List className={classes.listContainer}>                                                
                {attachments.length> 0 ? attachments
                    .filter(f => (
                        f.name.toLowerCase().indexOf(keyword.toLowerCase())!==-1 ||  
                        f.attachment_user.name.toLowerCase().indexOf(keyword.toLowerCase())!==-1 ||  
                        f.attachment_user.surname.toLowerCase().indexOf(keyword.toLowerCase())!==-1
                    ))
                    .map((item,index) => (
                        <FeatureFileListItem key={index} item={item} downloadFileHandler={downloadFileHandler} deleteAttachment={deleteAttachment} />
                    )) : (
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={`Nessun allegato trovato per questo elemento`} secondary={'Usa il form in alto per caricare nuovi allegati'} />
                    </ListItem>
                )}                    
            </List>
        )
    }, [attachments, classes, keyword, downloadFileHandler,deleteAttachment])

    return (
        <Grid container className={classes.root}>
            <FeatureTitle 
                is_generic={is_generic} 
                feature={feature}
                section={'Files'}  
                rightComponentPosition='flex-start'
                rightComponent={<Tooltip placement="left" title="Add new file"><IconButton edge="end" onClick={openAddDialog} className={classes.addIcon}><AddCircleIcon fontSize="large" /></IconButton></Tooltip>} 
            />
            <IconButton className={classes.closeIcon} onClick={onClosePanel}>
                <CloseIcon />
            </IconButton>
            <Dialog open={showAddDialog} onClose={closeAddDialog} TransitionComponent={Transition} aria-labelledby="add-title" aria-describedby="add-description">
                <DialogTitle id="add-title">{"Add File"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ad-description">Fill the form below in order to add a new file</DialogContentText>
                    <FeatureFileForm file={file} name={name} handleFileChange={handleFileChange} handleNameChange={handleNameChange} uploadFileHandler={uploadFileHandler} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAddDialog} color="primary">Cancel</Button>
                    <Button onClick={uploadFileHandler} color="primary" endIcon={<UploadIcon />}>Upload File</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}> 
                <TextField 
                    className={classes.searchInput}
                    fullWidth
                    placeholder="Cerca per nome file o utente" 
                    onChange={e => setKeyword(e.target.value)} 
                    value={keyword}  
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>                                                            
                    }}                             
                    color="secondary"
                />                             
                {renderAttachments}
            </Grid>            
        </Grid>
    )
}

export default AttachmentsComponent