import React, { useState } from "react";
import Draggable from "react-draggable";

import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import TabPanel from "@meetico/mappify/ui/tab-panel/tab-panel.component";
import SearchResults from "@meetico/mappify/lists/map-layers-control-search/map-layers-control-search.list";

import TabTitle from "./tab-title/tab-title.component";
import SearchComponent from "./search/search.component";
import LevelsComponent from "./levels/levels.component";
import WmsComponent from "./wms/wms.component";
import NotesComponent from "./notes/notes.component";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mapControl: {
        width: 350,
        overflow: "hidden",
        zIndex: 9999,
        cursor: "default",
        position: "absolute",
    },
    dragHandleIcon: {
        display: "flex",
        padding: "10px 0",
        marginRight: 10,
        cursor: "move",
    },
    accordion: {
        height: "100%",
        background: "#f5f5f5",
    },
    accordionSummary: {
        backgroundColor: `${theme.palette.common.white} !important`,
    },
    accordionSummaryFocused: {
        backgroundColor: `${theme.palette.common.white} !important`,
    },
    accordionSummaryExpanded: {
        marginBottom: `0 !important`,
        marginTop: `0 !important`,
    },
    accordionExpandIconContainer: {
        color: theme.palette.common.black,
        "&:hover": {
            background: "transparent",
        },
    },
    accordionExpandIcon: {
        fontSize: 40,
    },
    tabs: {
        width: "100%",
        minHeight: 20,
        background: "#f5f5f5",
    },
    tab: {
        minWidth: 85,
        fontWeight: 400,
        minHeight: 20,
        padding: `0px 12px`,
        lineHeight: 1,
        color: `${theme.palette.common.black} !important`,
    },
    tabSelected: {
        fontWeight: 400,
        color: `${theme.palette.common.black} !important`,
    },
    tabIcon: {
        color: `${theme.palette.common.black} !important`,
    },
}));

const MapLayers = ({
    map_id,
    user,
    wms,
    notes,
    onToggleWms,
    onToggleNote,
    layers,
    selectedLayer,
    onSetLayerSelected,
    onSetLayerShow,
    onSetLayerHide,
    onToggleLayersVisibility,
    layersVisibility,
    onToggleWmsVisibility,
    wmsVisibility,
    onToggleNotesVisibility,
    notesVisibility,
    onFeatureClick,
    onNoteClick,
}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [searchResults, setSearchResults] = useState();

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    const handleDrag = (e) => {
        e.stopPropagation();
    };

    const openWmsTab = () => {
        setKeyword("");
        setSearchResults(null);
        setValue(1);
    };
    const openNotesTab = (note) => {
        setKeyword("");
        setSearchResults(null);
        setValue(2);
        onNoteClick(note);
    };

    const onSearch = (e) => {
        setKeyword(e.target.value);
        const term = e.target.value;
        if (term && term.trim() !== "") {
            const results = [];
            layers.map((layer) => {
                if (layer.features && layer.features.length > 0) {
                    return layer.features.map((feature) => {
                        if (
                            (feature.name &&
                                feature.name
                                    .toLowerCase()
                                    .indexOf(term.toLowerCase()) !== -1) ||
                            feature.type
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) !== -1
                        ) {
                            results.push({ ...feature, layer });
                        }
                        return feature;
                    });
                }
                return layer;
            });
            wms.map((w) => {
                if (
                    (w.name &&
                        w.name.toLowerCase().indexOf(term.toLowerCase()) !==
                            -1) ||
                    w.url.toLowerCase().indexOf(term.toLowerCase()) !== -1
                ) {
                    results.push({ ...w, type: "wms" });
                }
                return w;
            });
            notes.map((note) => {
                if (
                    note.note &&
                    note.note.toLowerCase().indexOf(term.toLowerCase()) !== -1
                ) {
                    results.push({ ...note, type: "note" });
                }
                return note;
            });
            setSearchResults(results);
        } else {
            setSearchResults(null);
        }
    };

    return (
        <Draggable
            handle=".handle"
            bounds={{ left: 0, top: 80 }}
            defaultPosition={{ x: 20, y: 100 }}
            onStart={handleDrag}
            onDrag={handleDrag}
        >
            <div className={classes.mapControl}>
                <Accordion
                    className={classes.accordion}
                    expanded={expanded}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <AccordionSummary
                        className={classes.accordionSummary}
                        classes={{
                            focused: classes.accordionSummaryFocused,
                            expanded: classes.accordionSummaryExpanded,
                        }}
                        expandIcon={null}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <div
                                    className={[
                                        "handle",
                                        classes.dragHandleIcon,
                                    ].join(" ")}
                                >
                                    <DragHandleIcon />
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <SearchComponent
                                    onSearch={onSearch}
                                    keyword={keyword}
                                />
                            </Grid>
                            <Grid item>
                                <IconButton
                                    size="small"
                                    onClick={() => setExpanded(!expanded)}
                                    className={
                                        classes.accordionExpandIconContainer
                                    }
                                >
                                    {expanded ? (
                                        <ArrowDropDownIcon
                                            className={
                                                classes.accordionExpandIcon
                                            }
                                        />
                                    ) : (
                                        <ArrowDropUpIcon
                                            className={
                                                classes.accordionExpandIcon
                                            }
                                        />
                                    )}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails
                        style={{
                            background: "#f5f5f5",
                            flexDirection: "column",
                        }}
                    >
                        {!searchResults ? (
                            <React.Fragment>
                                <Tabs
                                    className={classes.tabs}
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <Tab
                                        className={classes.tab}
                                        classes={{
                                            selected: classes.tabSelected,
                                        }}
                                        wrapped={true}
                                        label={
                                            <TabTitle
                                                toggleVisibility={
                                                    onToggleLayersVisibility
                                                }
                                                visibility={layersVisibility}
                                                tabTitle={"Layers"}
                                            />
                                        }
                                    />
                                    <Tab
                                        className={classes.tab}
                                        classes={{
                                            selected: classes.tabSelected,
                                        }}
                                        wrapped={true}
                                        label={
                                            <TabTitle
                                                toggleVisibility={
                                                    onToggleWmsVisibility
                                                }
                                                visibility={wmsVisibility}
                                                tabTitle={"WMS"}
                                            />
                                        }
                                    />
                                    <Tab
                                        className={classes.tab}
                                        classes={{
                                            selected: classes.tabSelected,
                                        }}
                                        wrapped={true}
                                        label={
                                            <TabTitle
                                                toggleVisibility={
                                                    onToggleNotesVisibility
                                                }
                                                visibility={notesVisibility}
                                                tabTitle={"Notes"}
                                            />
                                        }
                                    />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <LevelsComponent
                                        map_id={map_id}
                                        user={user}
                                        selectedLayer={selectedLayer}
                                        layers={layers}
                                        onSetLayerSelected={onSetLayerSelected}
                                        onSetLayerShow={onSetLayerShow}
                                        onSetLayerHide={onSetLayerHide}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <WmsComponent
                                        map_id={map_id}
                                        user={user}
                                        wms={wms}
                                        onToggleWms={onToggleWms}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <NotesComponent
                                        map_id={map_id}
                                        user={user}
                                        notes={notes}
                                        onToggleNote={onToggleNote}
                                    />
                                </TabPanel>
                            </React.Fragment>
                        ) : (
                            <SearchResults
                                results={searchResults}
                                onFeatureClick={onFeatureClick}
                                openWmsTab={openWmsTab}
                                openNotesTab={openNotesTab}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
        </Draggable>
    );
};

export default MapLayers;
