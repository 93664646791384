import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { socket } from '@meetico/mappify/layouts/map.layout'
import { PROJECT_PREFIX } from '@meetico/mappify/core/config'

import { makeStyles } from '@material-ui/core/styles'

import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    editIcon: {
        color: theme.palette.common.black
    },
    attributeRow:{
        width: '100%',
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    attributeLabel:{
        color: theme.palette.grey[600],
        fontSize: 12
    },
    attributeValue: {
        fontSize: 12
    },
    actionButtonContainer:{
        textAlign: 'center'
    }
}))

const FeatureAttributesList = ({ map_id, feature_uuid, attribute, editAttributeTrigger }) => {
    const classes = useStyles()
    const [status, setStatus] = useState('hide')
    const [anchorEl, setAnchorEl] = useState(null)
    const [showDeleteDialog, setDeleteDialog] = useState(false);
    const openOptionMenu = Boolean(anchorEl)
    
    const openMenu = (event) => { setAnchorEl(event.currentTarget) }
    const closeMenu = () => { setAnchorEl(null) }    

    const openDeleteDialog = () => { setDeleteDialog(true) }
    const closeDeleteDialog = () => { setDeleteDialog(false) }

    const toggleAttributeStatus = () => status==='hide' ? setStatus('show') : setStatus('hide')

    const deleteAttribute = () => {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
        socket.emit('delete-feature-attribute', { map_id, user_id: user.id, uuid: attribute.uuid, feature_uuid })
        closeDeleteDialog()
        closeMenu()
    }
    
    return (
        <div key={attribute.uuid}>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="center" className={classes.attributeRow}>
                <Grid item xs={3}>
                    <Typography className={classes.attributeLabel}>{attribute.label}</Typography>
                </Grid>
                <Grid item xs={7}>
                    {attribute.type==='text' && <Typography className={classes.attributeValue}>{attribute.value}</Typography>}
                    {attribute.type==='link' && <Tooltip placement="top" title="click to open in a new tab"><a href={attribute.value} target="_blank" rel="noopener noreferrer" className={classes.attributeValue} >{attribute.value}</a></Tooltip>}
                    {attribute.type==='password' && (
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item>
                                {status==='hide' ? 
                                    <Typography className={classes.attributeValue}>*************</Typography> 
                                : 
                                    <Typography className={classes.attributeValue}>{attribute.value}</Typography> 
                                }
                            </Grid>
                            <Grid item>
                                <Tooltip placement="top" title={status==='hide' ? 'Show Value' : 'Hide Value'}>
                                    <IconButton edge="end" onClick={toggleAttributeStatus}>
                                        {status==='hide' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={1} className={classes.actionButtonContainer}>
                    <Tooltip placement="top" title="Edit Attribute">
                        <IconButton className={classes.editIcon} edge="end" onClick={() => editAttributeTrigger(attribute)}><EditIcon /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1} className={classes.actionButtonContainer}>                            
                    <Tooltip placement="top" title="Remove Attribute">
                        <IconButton aria-controls="attribute-menu" className={classes.editIcon} edge="end" onClick={openMenu}><MoreVertIcon /></IconButton>
                    </Tooltip>
                    <Menu id="attribute-menu" anchorEl={anchorEl} keepMounted open={openOptionMenu} onClose={closeMenu} PaperProps={{ style: { width: '20ch' } }}>                        
                        <MenuItem onClick={openDeleteDialog}>
                            <DeleteIcon /> Delete
                        </MenuItem>                        
                    </Menu>
                </Grid>                    
            </Grid>
            <Divider />
            <Dialog open={showDeleteDialog} onClose={closeDeleteDialog} TransitionComponent={Transition} aria-labelledby="delete-title" aria-describedby="delete-description">
                <DialogTitle id="delete-title">{"Confirm Action"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-description">
                        Do you really wanto to delete this attribute?<br />
                        <small>This operation cannot be undone</small>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">Cancel</Button>
                    <Button onClick={deleteAttribute} color="primary" autoFocus>Confirm and Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FeatureAttributesList