import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import InvitationRequestItem from '@meetico/mappify/lists/invitation-request/invitation-request.list'

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '40%',
        maxWidth: 650,
    },
    dialogContentText:{
        outline: 'none',
        maxHeight:250,
        overflowY: 'scroll'
    } 
}));

const InvitationDialog = ({ invitations, open, closeDialog, acceptRequest, rejectRequest }) => {
    const classes = useStyles()
    return (
        <Dialog            
            open={open}
            onClose={closeDialog}
            PaperProps={{ className: classes.dialog }}
        >
            <DialogTitle>Richiest di condivisione</DialogTitle>
            <DialogContent dividers={true} className={classes.dialogContentText}>
                <List dense>
                    {invitations && invitations.map(invitation => (
                        <InvitationRequestItem key={invitation.id} invitation={invitation} acceptRequest={acceptRequest} rejectRequest={rejectRequest} />
                    ))}     
                </List>       
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} variant="contained" color="primary">
                    Fatto
                </Button>
            </DialogActions>
      </Dialog>
    )
}

export default InvitationDialog