import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    featureTitle: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 15
    },
    featureTitleId: {
        fontSize: 15,
        fontWeight: '400'
    }
}))

const FeatureTitle = ({ is_generic = false, feature, section, rightComponent = null, rightComponentPosition='space-between' }) => {
    const classes = useStyles()
    return (
        <Grid container direction="row" justify={rightComponent ? rightComponentPosition : 'flex-start'} alignItems="flex-start" spacing={2}>
            <Grid item>
                {is_generic ? ( 
                    <Typography className={classes.featureTitle}>{`Map ${section}`}</Typography>
                ) : (
                    <Typography className={classes.featureTitle}>{section ? `${section} - ` : ''}{feature.name ? feature.name : 'Map Feature'} <small className={classes.featureTitleId}>({feature.uuid})</small></Typography>
                )}
            </Grid>                
            {rightComponent ? (
                <Grid item>
                    {rightComponent}
                </Grid> 
            ) : null}
        </Grid>
    )
}

export default FeatureTitle