import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { socket } from '@meetico/mappify/map-socket'
import { withRouter } from 'react-router-dom'
import { divIcon } from 'leaflet'
import { toast } from 'react-toastify'
import { StoreContext } from '@meetico/mappify/context/store.context'

// import L from 'leaflet'
import { Map, TileLayer, Marker, ZoomControl, WMSTileLayer, FeatureGroup } from 'react-leaflet'
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import MapService from '@meetico/mappify/core/services/map.service'
import MapFeatures from '@meetico/mappify/map/map-features/map-features'
import MapLayersControl from '@meetico/mappify/map/map-layers-control/map-layers-control'
import FeatureContent from '@meetico/mappify/map/feature-content/feature-content'

import { makeStyles } from '@material-ui/core/styles';
import '@meetico/mappify/core/styles/divicon.css'

const useStyles = makeStyles({
    mapContainer: {
        position:'absolute',
        top: 0,
        left:0,
        width: '100%',
        height: '100vh',
        zIndex: 1
    },
    
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MapPage = ({ match }) => {    
    const classes = useStyles()    
    const { auth: { isAuth, user }, apiService, dataset, clearDataset } = useContext(StoreContext)    
    const map_id = match.params.map_id
    const [map, setMap] = useState()
    const [layers, setLayer] = useState([])
    const [layersVisibility, setLayersVisibility] = useState(true)
    const [wms, setWms] = useState([])
    const [wmsVisibility, setWmsVisibility] = useState(false)
    const [notes, setNotes] = useState([])
    const [notesVisibility, setNotesVisibility] = useState(true)
    const [showFeatureContent, setShowFeatureContent] = useState(false)
    const [selectedFeature, setSelectedFeature] = useState()
    const [selectedLayer, setSelectedLayer] = useState()
    const [allowed, setAllowed] = useState(false)
    const [enableFeatureContent, setEnableFeatureContent] = useState(true)
    const [openEditLayerMode, setOpenEditLayerMode] = useState(false)
    const mapRef = useRef()
    const noteRef = useRef()        

    const openEditLayerModeToast = () => {
        setOpenEditLayerMode(true);
    };

    const closeEditLayerModeToast = (_, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenEditLayerMode(false);
    };

    const listenForUpdate = useCallback(() => {

        socket.on("first-layers-update", async newLayers => {            
            const nl = await newLayers.map(l => { l.active=1; return l})            
            MapService.setLayers(nl)
            setLayer(nl)            
        });

        socket.on("layers-update", async newLayers => {       
            const nl = await newLayers.map(l => {
                const existingLayer = layers.find(el => el.uuid===l.uuid)                
                if(existingLayer){
                    l.active = existingLayer.active                    
                }else{
                    l.active=1                    
                }
                return l
            })            
            MapService.setLayers(nl)
            setLayer(nl)            
        });

        socket.on("wms-update", newWms => {            
            setWms(newWms)            
        });

        socket.on("notes-update", newNotes => {     
            setNotes(newNotes)            
        });

        socket.on("user-allowed", () => {
            setAllowed(true)            
        });

        socket.on("operation-success", () => {
            toast.success('Operazione effettuata con successo')
        })

    },[layers])
    
    useEffect(() => {        
        listenForUpdate()
        return () => {
            clearDataset(`map_${map_id}`)
            socket.off("first-layers-update")
            socket.off("layers-update")
            socket.off("wms-update")
            socket.off("notes-update")
            socket.off("user-allowed")
            socket.off("operation-success")
        };
        
    },[map_id,clearDataset,listenForUpdate])

    useEffect(() => {        
        if(dataset[`map_${map_id}`]){                        
            setMap({...dataset[`map_${map_id}`].map, mapRef })            
        }
    }, [dataset,map_id])

    useEffect(() => {     
        if(isAuth){
            if(map_id) apiService(`/map/${map_id}`,`map_${map_id}`,'get',{})
        }           
    },[isAuth,apiService,map_id])

    const _enableFeatureClik = () => setEnableFeatureContent(true)
    const _disableFeatureClik = () => setEnableFeatureContent(false)

    const _centerMapToFeature = feature => {   
        const mapLayers = Object.values(map.mapRef.current.leafletElement._layers)        
        mapLayers.forEach(l => {            
            if(l.options && l.options.id === feature.uuid){
                let center;
                if(feature.type==='circle' || feature.type==='marker' || feature.type==='circlemarker' || feature.type==='note') center = l.getLatLng()
                else center = l.getCenter()
                map.mapRef.current.leafletElement.panTo(center);
            }
        })
    }

    
    const _onFeatureContentClose = () => {
        setSelectedFeature(null)
        setShowFeatureContent(false)
    }

    const _onSetLayerShow = (uuid) => {
        setLayersVisibility(true)
        setLayer([
            ...layers.map(l => {
                if(l.uuid===uuid) l.active = 1
                return l
            })
        ])        
    }

    const _onSetLayerHide = (uuid) => {
        setLayer([
            ...layers.map(l => {
                if(l.uuid===uuid) l.active = 0
                return l
            })
        ])
        const activeLayers = layers.reduce((acc,l) => {
            if(l.active) acc+=1
            return acc
        },0)
        if(activeLayers>0) setLayersVisibility(true)
        else setLayersVisibility(false)
    }

    const _onToggleLayersVisibility = () => {        
        setLayer([
            ...layers.map(l => {
                l.active = !layersVisibility
                return l
            })
        ])
        setLayersVisibility(!layersVisibility)
    }

    const _onSetLayerSelected = (layer) => {
        if(!layer){
            setSelectedLayer(null)
            closeEditLayerModeToast()
        }
        else if(!selectedLayer){            
            const mapLayers = Object.values(map.mapRef.current.leafletElement._layers)        
            mapLayers.forEach(l => {                
                if(layer.layerRef.current && l._leaflet_id === layer.layerRef.current.leafletElement._leaflet_id){
                    l.bringToFront()
                }
            })
            setSelectedLayer(layer)
            openEditLayerModeToast()
        }else{
            if(layer.uuid===selectedLayer.uuid){
                setSelectedLayer(null)
                closeEditLayerModeToast()
            }else{
                const mapLayers = Object.values(map.mapRef.current.leafletElement._layers)        
                mapLayers.forEach(l => {                
                    if(l._leaflet_id === layer.layerRef.current.leafletElement._leaflet_id){
                        l.bringToFront()
                    }
                })
                setSelectedLayer(layer)   
                openEditLayerModeToast()
            }        
        }        
    }

    const _onFeatureClick = (feature,layer) => {
        if(enableFeatureContent){
            _centerMapToFeature(feature)
            setSelectedFeature(feature)
            setShowFeatureContent(true)
            if(!layer.active) _onSetLayerShow(layer.uuid)
            _onSetLayerSelected(layer)
        }        
    }

    const _onCreate = async (e,layer) => {        
        layer.layerRef.current.leafletElement.getLayers().map(l => {                     
            if(!l.options.id){
                map.mapRef.current.leafletElement.removeLayer(l)
                layer.layerRef.current.leafletElement.removeLayer(l)
            }
            return l
        })
        const feature = await MapService.createMapDraw(e)        
        socket.emit('new-feature', { map_id, layer_uuid: layer.uuid, feature })     
    }

    const _onEdited = async (e,layer) => {
        const updatedLayers = Object.values(e.layers._layers)        
        await updatedLayers.map(async l => {
            const feature = await MapService.updateMapDraw(layer.uuid, l.options.id, l)            
            socket.emit('update-feature', { map_id, layer_uuid: layer.uuid, feature })
        })
    }
    const _onDeleted = (e,layer) => {
        const deletedLayers = Object.values(e.layers._layers)        
        deletedLayers.map(l => {            
            const uuid = l.options.id  
            socket.emit('delete-feature', { map_id, layer_uuid: layer.uuid, uuid })
            return l
        })        
    }

    const _onToggleWms = uuid => {                
        setWms(wms.map(w => {            
            if(w.uuid===uuid){                
                w.active = !w.active                
            }
            return w
        }))
        const activeWms = wms.reduce((acc,w) => {
            if(w.active) acc+=1
            return acc
        },0)
        if(activeWms>0) setWmsVisibility(true)
        else setWmsVisibility(false)
    }

    const _onToggleWmsVisibility = () => {        
        setWms(wms.map(w => {                        
            w.active = !wmsVisibility
            return w
        }))
        setWmsVisibility(!wmsVisibility)
    }

    const _onToggleNote = uuid => {                
        setNotes(notes.map(n => {            
            if(n.uuid===uuid){                
                n.active = !n.active                
            }
            return n
        }))
        const activeNotes = notes.reduce((acc,n) => {
            if(n.active) acc+=1
            return acc
        },0)
        if(activeNotes>0) setNotesVisibility(true)
        else setNotesVisibility(false)
    }

    const _onToggleNotesVisibility = () => {        
        setNotes(notes.map(n => {                        
            n.active = !notesVisibility
            return n
        }))
        setNotesVisibility(!notesVisibility)
    }

    const _onNotePositionChange = useCallback((e,note) => {
        const position = JSON.stringify([e.target._latlng.lat, e.target._latlng.lng])
        socket.emit('update-note', { map_id, user_id: user.id, uuid: note.uuid, note: note.note, position })
    },[map_id, user.id])

    const renderWms = useMemo(() => {
        if(wms.length>0){
            return wms.filter(w => w.active).map( w => {                       
                return (
                    <WMSTileLayer 
                        key={w.uuid}
                        layers={w.layer}
                        url={w.url}
                        transparent={true}
                    />
                )
            })
        }
        return null
    },[wms])

    const renderNotes = useMemo(() => {
        if(notes.length>0){            
            return notes.filter(note => note.active).map(note => {                       
                const icon = divIcon({        
                    className: "divicon",
                    html: `<div>${note.note}</div>`
                });
            
                return (
                    <Marker 
                        key={note.uuid}
                        id={note.uuid}
                        icon={icon}        
                        draggable
                        zIndexOffset={1000}        
                        ondragend={e => _onNotePositionChange(e,note)}
                        position={note.position}        
                    />
                )                   
            })
        }
        return null
    },[notes, _onNotePositionChange])

    // const enableMouseWheelZoom = () => {
    //     map.mapRef.current.leafletElement.scrollWheelZoom.enable()        
    // }
    // const disableMouseWheelZoom = () => {
    //     map.mapRef.current.leafletElement.scrollWheelZoom.disable()        
    // }    
    
    if(!map || !user || !allowed) return <CircularProgress size={200} />    

    return (
        <div className={classes.mapContainer}>    
            <Map                         
                center={[map.lat, map.long]}
                zoom={map.zoom}
                zoomControl={false}               
                doubleClickZoom={true}
                scrollWheelZoom={false}
                style={{width: '100%', height: '100%' }}
                ref={map.mapRef}                
                // onblur={disableMouseWheelZoom}
                // onfocus={enableMouseWheelZoom}
            >                

                <ZoomControl position="topright" />
                                   
                <MapLayersControl 
                    map_id={map_id} 
                    user={user} 
                    layers={layers} 
                    wms={wms} 
                    notes={notes}
                    onToggleWms={_onToggleWms}
                    onToggleNote={_onToggleNote}
                    selectedLayer={selectedLayer}
                    onSetLayerSelected={_onSetLayerSelected}
                    onSetLayerShow={_onSetLayerShow}
                    onSetLayerHide={_onSetLayerHide}
                    layersVisibility={layersVisibility}
                    wmsVisibility={wmsVisibility}
                    notesVisibility={notesVisibility}
                    onToggleLayersVisibility={_onToggleLayersVisibility}
                    onToggleWmsVisibility={_onToggleWmsVisibility}
                    onToggleNotesVisibility={_onToggleNotesVisibility}
                    onFeatureClick={_onFeatureClick}
                    onNoteClick={_centerMapToFeature}
                />                                                

                {layers && layers.filter(layer => layer.active).map(layer => {
                    layer.layerRef = React.createRef()                    
                    return (
                        <MapFeatures                            
                            selectedFeature={selectedFeature}
                            selectedLayer={selectedLayer}
                            key={layer.uuid}
                            layerRef={layer.layerRef}
                            features={layer.features}
                            layer={layer}
                            onFeatureClick={_onFeatureClick}
                            onEdited={_onEdited}
                            onCreated={_onCreate}
                            onDeleted={_onDeleted}
                            enableFeatureClik={_enableFeatureClik}
                            disableFeatureClik={_disableFeatureClik}
                        />
                    )                        
                })}
                
                {renderWms}

                <FeatureGroup ref={noteRef}>
                    {renderNotes}
                </FeatureGroup>
                
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />            
            </Map>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={6000}
                open={openEditLayerMode}
                onClose={closeEditLayerModeToast}
                key={`edit-layer-mode-toast`}
            >
                <Alert severity="info" onClose={closeEditLayerModeToast}>
                    Modalita disegno su mappa abilitato
                </Alert>
            </Snackbar>
            <Drawer PaperProps={{style: {overflowY: 'hidden'}}} anchor={'right'} open={showFeatureContent} onClose={_onFeatureContentClose}>
                {selectedFeature && <FeatureContent map={map} feature={selectedFeature} map_id={map_id} onClosePanel={_onFeatureContentClose} />}
            </Drawer>
        </div>
    )
}

export default withRouter(MapPage)