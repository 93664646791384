import React, { Fragment, useState } from 'react'
import { socket } from '../../../../layout/map.layout'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MapWmsListItem from '../../../lists/map-wms-list-item/map-wms-list-item'
import { toast } from 'react-toastify'
import urlExists from 'url-exists'

const useStyles = makeStyles((theme) => ({
    addLayerFab:{
        width: 30,
        height: 30,
        minHeight: 30,
        boxShadow: 'none',
        color: '#fff'
    },
    container: {
        marginTop: 10
    },
    wmsListContainer: {
        height: 'auto',
        maxHeight: 185,        
        overflowX: 'hidden',
        overflowY: 'auto',
        flexWrap: 'nowrap', 
        marginTop: theme.spacing(2),        
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    },
    sectionTitle: { 
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    emptyText:{
        fontSize: 14,
        marginTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    emptySubtext:{
        fontSize: 12,
        marginTop: theme.spacing(1)
    },
    emptyAddButton:{
        marginTop: theme.spacing(2)
    }
}))

const WmsComponent = ({ map_id, user, wms, onToggleWms }) => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [form, setForm] = useState({ name: '', url: '', layer: '' })
    const [deleteWms, setDeleteWms] = useState()    

    const _onAddWms = () => {
        setOpenDialog(true)
    }

    const _onEditWms = wms => {
        setForm({ name: wms.name, url: wms.url, layer: wms.layer, uuid: wms.uuid })
        setOpenDialog(true)
    }
    
    const _onDeleteWms = (wms) => {
        setDeleteWms(wms)
        setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false)
    }

    const handleInputChange = (name,value) => {        
        setForm({
            ...form,
            [name]: value
        })
    }

    const deleteWmsHandler = () => {
        socket.emit('delete-wms', { map_id, uuid: deleteWms.uuid })
        setOpenDeleteDialog(false)
    }

    const submitForm = async () => {
        urlExists(form.url, function(err, exists) {
            if(exists){
                setOpenDialog(false)
                if(!form.uuid) socket.emit('new-wms', { map_id, user_id: user.id, ...form })
                else socket.emit('update-wms', { map_id, user_id: user.id, ...form })
                setForm({ name: '', url: '', layer: '' })
            }else{
                toast.error('The url provided does not seems to exists. Please amend it in order to save the new WMS.')
            }
        })
    }
    
    return (
        <Fragment>
            <Grid container direction="column" justify="flex-start" align="flex-start" spacing={2} className={classes.container}>
                <Grid item>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionTitle}>Manage WMS</Typography>                                    
                        </Grid>    
                        <Grid item>   
                            <Tooltip placement="top" title="Add new WMS">
                                <Fab color="secondary" aria-label="add" size="small" className={classes.addLayerFab} onClick={_onAddWms}>                                        
                                    <AddIcon />                                        
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item className={classes.wmsListContainer}>
                    {(wms && wms.length>0) && wms.map(w => (
                        <MapWmsListItem 
                            key={w.uuid} 
                            wms={w}          
                            _onToggleWms={onToggleWms}                  
                            _onEdit={_onEditWms}                                     
                            _onDelete={_onDeleteWms} 
                        />
                    ))}
                    {(wms && wms.length === 0) && (
                        <Fragment>
                            <Typography className={classes.emptyText} gutterBottom align="center">No WMS added yet</Typography>
                            <Typography className={classes.emptySubtext} gutterBottom align="left">In order to add your first WMS, please click on the "Add new WMS" button</Typography>
                            <Button className={classes.emptyAddButton} fullWidth startIcon={<AddIcon />} variant="outlined" color="secondary" size="small" onClick={_onAddWms}>Add new WMS</Button>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{!form.uuid ? 'Add new' : 'Edit'} WMS</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Nome Wms"
                                type="text"
                                onChange={(e) => handleInputChange('name',e.target.value)}
                                value={form.name}
                                fullWidth
                            />    
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="url"
                                label="Url Wms"
                                type="text"
                                onChange={(e) => handleInputChange('url',e.target.value)}
                                value={form.url}
                                fullWidth
                            />    
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="layer"
                                label="Layer Wms"
                                type="text"
                                onChange={(e) => handleInputChange('layer',e.target.value)}
                                value={form.layer}
                                fullWidth
                            />    
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={submitForm} color="primary">
                        {!form.uuid ? 'Add' : 'Edit'} Wms
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm delete action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this WMS permanently?<br />
                        <small>This action cannot be undone</small>
                    </DialogContentText>                   
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleDeleteDialogClose} color="primary">
                        cancel
                    </Button>
                    <Button variant="contained" onClick={deleteWmsHandler} color="primary">
                        confirm and delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default WmsComponent