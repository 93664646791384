import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import InfoIcon from '@material-ui/icons/Info'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import FeatureAttributesList from '@meetico/mappify/lists/feature-attributes/feature-attributes.list'
import FeatureAttributeForm from '@meetico/mappify/forms/feature-attribute.form'

import { socket } from '@meetico/mappify/layouts/map.layout'
import { toast } from 'react-toastify'
import { PROJECT_PREFIX } from '@meetico/mappify/core/config'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%',
        marginTop: theme.spacing(2)
    },
    sectionTitle:{
        fontSize: 16,
        fontWeight: 'bold'
    },
    actionIcon:{
        color: theme.palette.common.black
    },
    attributesContainer:{
        height: 'auto',
        minHeight: 100,
        maxHeight: 240,
        overflowY: "auto",
        overflowX: "hidden",
        flexWrap: 'nowrap',        
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    }
}))

const initFormState = { type: '', label: '', value: '' }

const InfoAttributes = ({ map_id, feature }) => {
    const classes = useStyles()
    const [attributes,setAttributes] = useState([])
    const [editAttributeId, setEditAttributeId] = useState()
    const [showForm, setShowForm] = useState(false)
    const [form, setForm] = useState(initFormState)
    
    const toastId = React.useRef(null);

    useEffect(() => {        
        socket.emit('get-feature-attributes', { map_id, feature_uuid: feature.uuid })
        
        socket.on('feature-attributes', data => { 
            if(data.feature_uuid === feature.uuid) setAttributes(data.attributes)             
        })

        socket.on('refresh-attributes', data => {                        
            if(data.feature_uuid===feature.uuid) socket.emit('get-feature-attributes', { map_id, feature_uuid: feature.uuid })            
        })

        socket.on('attributes-operation-success', data => {            
            if(!toast.isActive(toastId.current)) {
                toastId.current = toast.success('Operation completed successfully')
            }            

            if(data.action==='create'){                                
                setShowForm(false)
                setForm(initFormState)
            }else if(data.action==='edit'){                
                setForm(initFormState)                
                setEditAttributeId(null)
            }
        })

        return () => {
            socket.off('get-feature-attributes')
            socket.off('feature-attributes')
            socket.off('refresh-attributes')            
            socket.off('operation-success')
        }
    },[feature.uuid, map_id])

    const handleInputChange = useCallback((name, value) => {        
        setForm({
            ...form,
            [name]: value
        })
    },[form])

    const closeForm = () => {
        setShowForm(false)
        setForm(initFormState)
    }

    const handleSubmit = async () => {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
        const attribute = { user_id: user.id, map_id, layer_uuid: feature.layer_uuid, feature_uuid:feature.uuid, type: form.type, label: form.label, value: form.value}
        socket.emit('create-feature-attribute', attribute)
    }

    const editAttributeTrigger = (attribute) => {
        setForm(attribute)
        setEditAttributeId(attribute.uuid)
    }

    const closeEditForm = () => {
        setEditAttributeId(null)
        setForm(initFormState)
    }

    const handleEditSubmit = useCallback(async () => {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
        const attribute = { uuid: form.uuid, user_id: user.id, map_id, layer_uuid: feature.layer_uuid, feature_uuid:feature.uuid, type: form.type, label: form.label, value: form.value}
        socket.emit('edit-feature-attribute', attribute)        
    },[feature.layer_uuid,feature.uuid,form,map_id])

    const renderAttributes = useMemo(() => {
        if(!attributes) return <CircularProgress size={50} />
        if(attributes.length===0) return (
            <React.Fragment>
                <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
                    <Grid item><IconButton onClick={() => {}}><InfoIcon /></IconButton></Grid>
                    <Grid item><Typography variant="body2">No attributes available for this feature</Typography></Grid>
                </Grid>
            </React.Fragment>
        )

        return attributes.map(attribute => {
            
            if(editAttributeId && editAttributeId===attribute.uuid){
                return (                    
                    <div key={attribute.uuid} style={{width: '100%'}}>
                        <FeatureAttributeForm 
                            form={form} 
                            handleInputChange={handleInputChange} 
                            handleSubmit={handleEditSubmit} 
                            closeForm={closeEditForm} 
                        />
                        <Divider />
                    </div>
                )
            }

            return (
                <FeatureAttributesList
                    key={attribute.uuid}
                    map_id={map_id}
                    feature_uuid={feature.uuid}
                    attribute={attribute}
                    editAttributeTrigger={editAttributeTrigger}
                />          
            )
        })
    },[attributes, editAttributeId, form, map_id, feature.uuid, handleEditSubmit, handleInputChange])

    return (
        <Grid container direction="column" justify="center" className={classes.root} spacing={1}>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">                    
                <Grid item>
                    <Typography className={classes.sectionTitle}>Feature attributes</Typography>
                </Grid>
                <Grid item>
                    {(!showForm && !editAttributeId) ? (
                        <Button color="primary" variant="text" onClick={() => setShowForm(true)} startIcon={<AddCircleOutlineIcon />}>
                            Add new
                        </Button>
                    ) : null}
                </Grid>            
            </Grid>
            {showForm && <FeatureAttributeForm form={form} handleInputChange={handleInputChange} handleSubmit={handleSubmit} closeForm={closeForm} />}
            <Grid item xs={12} container direction="column" className={classes.attributesContainer}>
                {renderAttributes}                
            </Grid>                        
        </Grid>
    )
}

export default InfoAttributes