import React from 'react'
import { withRouter } from 'react-router-dom'
import AppLayout from '../../layout/app.layout'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import PageTitle from '../../components/ui/page-title/page-title.component'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(8),      
    },
    content:{
        textAlign: 'center'
    },
    button:{
        marginTop: theme.spacing(2)
    }
}))

const NotFoundPage = ({ history }) => {
    const classes = useStyles()
    return (
        <AppLayout layout={'no-toolbar'} breadcrumb={false} toolbarPosition={'fixed'}>
            <Container component="main" maxWidth="sm">
                <Card className={classes.container}>
                    <CardContent>
                        <Grid container spacing={4}> 
                            <Grid item xs={12}>
                                <PageTitle title="Ops!" subtitle="Non abbiamo trovato la tua pagina" />
                            </Grid>           
                            <Grid item xs={12} className={classes.content}>
                                <Typography variant="body1">La pagina cercata potrebbe non esistere più o aver cambiato indirizzo.</Typography>
                                <Button className={classes.button} variant="contained" color="primary" onClick={() => history.push('/')}>Torna alla piattaforma</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card> 
            </Container>   
        </AppLayout>
    )
}

export default withRouter(NotFoundPage)