import React from 'react'
import { withRouter } from 'react-router-dom'
import AppLayout from '../../layout/app.layout'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import PageTitle from '../../components/ui/page-title/page-title.component'

const useStyles = makeStyles(theme => ({
    container: {
        marginVertical: theme.spacing(8),
    },
    content:{
        textAlign: 'justify'
    }
}))

const PrivacyPolicy = ({ history }) => {
    const classes = useStyles()
    return (
        <AppLayout layout={'no-toolbar'} breadcrumb={false} toolbarPosition={'fixed'}>
            <Container component="main" maxWidth="lg" style={{marginTop: 100,marginBottom: 100}}>
                <Card className={classes.container}>
                    <CardContent>
                        <Grid container spacing={4}> 
                            <Grid item xs={12}>
                                <PageTitle title="Privacy Policy" subtitle="Aggiornato al 15 Gennaio 2020" />
                            </Grid>           
                            <Grid item xs={12} className={classes.content}>
                                <Typography variant="body1">
                                    La presente <b>Privacy Policy</b> di Mappify by Meetico LTD, con sede in [indirizzo] (Titolare del trattamento) ha l’obiettivo di fornire ogni delucidazione sulla politica adottata dalla stessa in tema di trattamento di dati personali degli utenti relativamente all’utilizzo dell’applicazione “Giscake”.<br />
                                    Prendiamo in seria considerazione la tutela della privacy, cioè la riservatezza dei dati personali dei propri utenti. I principi di seguito delineati stanno alla base del trattamento dei dati personali degli utenti.<br />
                                    Tutti gli utenti hanno prestato il consenso al trattamento dei loro dati personali, in sede di iscrizione al progetto sopra menzionato.<br /><br />
                                    
                                    I dati verranno raccolti solo ove necessario e in conformità alla normativa vigente. Mappify by Meetico LTD fornirà sempre informazioni chiare relative all’uso che viene fatto dei vostri dati personali e sarà sempre trasparente con gli utenti in merito ai dati che vengono raccolti, l’uso che se ne fa, con chi vengono condivisi e l’ufficio da contattare per ogni richiesta. <br />
                                    Mappify by Meetico LTD adotta tutte le misure necessarie per salvaguardare i dati degli utenti da un uso scorretto e per trattarli in modo sicuro, rispettando tutte le leggi e i regolamenti applicabili a tutela della privacy, collaborando con le autorità preposte alla protezione dei dati personali e agendo, in ogni caso, in conformità con i principi generalmente riconosciuti relativi alla protezione dei dati personali.<br />
                                    In qualsiasi momento, Lei potrà esercitare i diritti di cui agli art. 15 e ss. del Regolamento UE 679/2016 tra cui quelli di ottenere dal titolare l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che la riguarda, o di opporsi, per motivi legittimi, al loro trattamento, oltre al diritto alla portabilità dei dati, scrivendo a privacy@piazzadeimestieri.it<br /><br />
                                    Qualora ravvisasse una violazione dei Suoi diritti può rivolgersi all’autorità di controllo competente ai sensi dell’art. 77 del Regolamento UE 679/2016.<br /><br /><br />
                                </Typography>

                                <Typography variant="h5">TRATTAMENTO DEI DATI E CONSENSO DEGLI UTENTI</Typography>
                                <Typography variant="body1">
                                    Mappify by Meetico LTD si limiterà a raccogliere, utilizzare o comunicare i dati personali degli utenti nel pieno rispetto della legge. Qualora necessario, di Mappify by Meetico LTD chiederà di esprimere esplicitamente il loro consenso.<br /><br /><br />
                                </Typography>
                                
                                <Typography variant="h5">LUOGO DEL TRATTAMENTO DEI DATI PERSONALI</Typography>
                                <Typography variant="body1">
                                    I trattamenti connessi all’applicazione hanno luogo presso la sede del titolare e sono curati solo da personale incaricato del trattamento.<br /><br /><br />
                                </Typography>
                                
                                <Typography variant="h5">DATI TRATTATI</Typography>
                                <Typography variant="body1">
                                    Per “dati personali”, nel contesto della presente Privacy Policy, s’intendono informazioni o frammenti di informazioni che potrebbero permettere la vostra identificazione.<br />
                                    Dati di navigazione<br /> 
                                    I sistemi informatici e le procedure software preposte al funzionamento dell’applicazione acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare i computer che si connettono all’applicazione. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono all’applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Questi dati potrebbero essere messi a disposizione di magistratura e Forze di Polizia per l'accertamento di responsabilità in caso di illeciti commessi attraverso l’applicazione o di reati informatici ai danni della stessa.                                
                                </Typography>                                
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card> 
            </Container>   
        </AppLayout>
    )
}

export default withRouter(PrivacyPolicy)