import React, { Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MapIcon from '@material-ui/icons/Map'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: 0
    },
    avatarContainer: {        
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    avatar:{
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '1rem',
        backgroundColor: theme.palette.secondary.main,
        color: '#fff'
    }
}))

const InvitationRequestItem = ({ invitation, acceptRequest, rejectRequest }) => {
    const classes = useStyles()    
    return (        
        <ListItem justify="flex-start" alignItems="center" className={classes.listItem}>
            <ListItemAvatar className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>
                    <MapIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={(
                    <Fragment>
                        <Typography variant="subtitle2">Invito ricevuto da: {`${invitation.sender.name} ${invitation.sender.surname} `}</Typography>
                        <Typography variant="subtitle2">Per collaborare su: {`${invitation.Map.name}`}</Typography>
                    </Fragment>
                )}
                secondary={invitation.Map.description ?<Typography variant="caption">Info mappa: {`${invitation.Map.description}`}</Typography> : null}
            />
            <ListItemSecondaryAction spacing={2}>
                <Tooltip placement="top" title="Accetta l'invito">
                    <IconButton edge="end" aria-label="accept" onClick={() => acceptRequest(invitation)}>
                        <CheckIcon color="secondary" />
                    </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Rifiuta l'invito">
                    <IconButton edge="end" aria-label="reject" onClick={() => rejectRequest(invitation)}>
                        <CloseIcon color="error" />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>                        
    )
}

export default InvitationRequestItem