import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../../theme/images/logo.svg'

import { StoreContext } from '../../context/store.context.js'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem 1.5rem'
    },    
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }    
}));

const Login = ({ history }) => {

    const classes = useStyles();
    const { auth: { signUp } } = useContext(StoreContext)
    const [form, setForm] = useState({ user_type_id:1, active: 1 })
    
    const signUpHandler = async (e) => {
        e.preventDefault();        
        if(await signUp(form)) history.push(`/`)
    }

    const setFormValue = (name,value) => {
        const updatedForm = {...form, [name]: value }
        setForm(updatedForm)
    }

    const handleFormSubmit = () => {
        const {name, surname, email, password, conf_password} = form
        return !name || !surname || !email || !password || !conf_password || (password && conf_password && password!==conf_password)
    }

    return (
        <Container component="main" maxWidth="sm">        
            <Paper className={classes.paper}>
                <img src={Logo} width="35" alt="logo" />
                <Typography component="h1" variant="h5">
                    Crea il tuo account
                </Typography>
                <form className={classes.form} noValidate onSubmit={signUpHandler}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Nome"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                onChange={(e) => setFormValue('name',e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="surname"
                                label="Cognome"
                                name="surname"
                                autoComplete="surname"                        
                                onChange={(e) => setFormValue('surname',e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                type="email"                        
                                onChange={(e) => setFormValue('email',e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Cellulare"
                                name="phone"
                                autoComplete="phone"                                 
                                onChange={(e) => setFormValue('phone',e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => setFormValue('password',e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="conf_password"
                                label="Conferma Password"
                                type="password"
                                id="conf_password"
                                autoComplete="current-password"
                                onChange={(e) => setFormValue('conf_password',e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        disabled={handleFormSubmit()}
                    >
                        Registrati
                    </Button>
                    <Grid container>
                        <Grid item xs align="center">
                            <Link href="/" variant="body2">
                                Hai già un account? Clicca qui
                            </Link>
                        </Grid>                        
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Typography variant="body2" className={classes.bottomText} align="center">
                    {`Mappify - Copyright © Meetico LTD ${new Date().getFullYear()}.`}
                </Typography>
            </Box>
        </Container>
    );
}

export default withRouter(Login)