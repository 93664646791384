import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import { StoreContext } from '@meetico/mappify/context/store.context'
import { socket } from '@meetico/mappify/layouts/map.layout'

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from "@material-ui/core/IconButton"
import Drawer from '@material-ui/core/Drawer'
import Badge from '@material-ui/core/Badge'

import makeStyles from "@material-ui/core/styles/makeStyles"

import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ForumIcon from '@material-ui/icons/Forum'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'


import ConnectedUsers from "@meetico/mappify/map/connected-users/connected-users.component";
import UserDropdown from '@meetico/mappify/ui/user-dropdown/user-dropdown.component'
import MapGenericDrawer from '@meetico/mappify/map/map-generic-drawer/map-generic-drawer.component'

import Logo from '@meetico/mappify/core/theme/images/logo.svg'
import '@meetico/mappify/core/styles/map.css'

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    whiteText: {
        color: 'white'
    },
    appBar: {
        zIndex: `${theme.zIndex.drawer + 2}!important`,        
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '98%',
        top: 10,
        left: 15,
        borderRadius: 40
    },
    menu: {
        '& .MuiMenu-paper': {
            width: '350px',
            borderRadius: 0,
            padding: '1rem',
            '& .MuiListItem-button': {
                borderBottom: '1px solid rgba(0,0,0,.12)',
                padding: '1rem'
            }
        }
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    }
}));

const MapToolbar = ({ position, history, match }) => {    
    const classes = useStyles();
    const { auth: { user } } = useContext(StoreContext)    
    const [anchorEl, setAnchorEl] = useState(null)
    const [showMapDrawer, setShowMapDrawer] = useState(false)
    const [drawerInitSection, setDrawerInitSection] = useState(0)
    const [hideNotification, setHideNotifications] = useState(true)    
    const map_id = match.params.map_id
    
    const goBack = () => {
        socket.emit('unsubscribe', { map_id, user_id: user.id  })
        history.push('/')
    }    

    const  closeSnackbar = () => { setHideNotifications(true) }

    const openUserDropdown = (event) => { setAnchorEl(event.currentTarget) }
    const closeUserDropdown = (e) => { setAnchorEl(null) }

    const _onDrawerOpen = useCallback((section) => { 
        setShowMapDrawer(true)
        setDrawerInitSection(section)        
    },[])
    const _onDrawerClose = () => { 
        setShowMapDrawer(false)
        if(drawerInitSection===1){
            setHideNotifications(true)
        }
        setDrawerInitSection(0)
    }

    useEffect(() => {                

        socket.on('new-map-comments', () => {               
            if(!showMapDrawer || (showMapDrawer && drawerInitSection===0)){
                setHideNotifications(false) 
            }
        })

        return () => { socket.off('new-map-comments') }        
    },[showMapDrawer,drawerInitSection]) 
    
    const renderChatIcon = useMemo(() => {        
        return (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => _onDrawerOpen(1)}>
                <Badge color="error" variant="dot" invisible={hideNotification===false ? false : true}>                                    
                    <ForumIcon fontSize="large" />
                </Badge>
            </IconButton>
        )
    },[hideNotification, _onDrawerOpen])

    return (
        <div className={classes.grow}>
            <AppBar position={position} className={classes.appBar}>
                <Toolbar>                    
                    <Grid container direction="row" justify={"space-between"} alignItems={"center"}>
                        <Grid item xs={5} container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item>
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={goBack}>
                                    <ArrowBackIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <ConnectedUsers map_id={map_id} />
                            </Grid>                                                        
                        </Grid>
                        <Grid item xs={2} align="center">
                            <Button onClick={() => history.push('/home')} color={"inherit"}>
                                <img src={Logo} width="30" alt="logo" />
                            </Button>
                        </Grid>
                        <Grid item xs={5} container direction="row" justify="flex-end" alignItems="center" spacing={3}>
                            <Grid item>
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => _onDrawerOpen(0)}>
                                    <NoteAddIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                {renderChatIcon}
                            </Grid>
                            <Grid item>
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={openUserDropdown}>
                                    <AccountCircleIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={!hideNotification} autoHideDuration={6000} onClose={closeSnackbar}
                message="New Chat Message" onClick={() => _onDrawerOpen(1)} 
                action={                                          
                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
                        <CloseIcon fontSize="small" />
                    </IconButton>                    
                }               
            />
            <UserDropdown anchorEl={anchorEl} handleClose={closeUserDropdown} />
            <Drawer PaperProps={{style: {overflowY: 'hidden'}}} anchor={'right'} open={showMapDrawer} onClose={_onDrawerClose}>
                <MapGenericDrawer map_id={map_id} drawerInitSection={drawerInitSection} onClose={_onDrawerClose} />
            </Drawer>
        </div>
    );
};

MapToolbar.defaultProps = {
    toggleDrawer: () => console.error('Missing toggleDrawer function'),
    position: 'absolute'
};

MapToolbar.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    position: PropTypes.string
};

export default withRouter(MapToolbar);
