import React, { Fragment, useContext, useEffect, useState, useCallback} from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Badge from '@material-ui/core/Badge'
import WarningIcon from '@material-ui/icons/Warning'
import PageTitle from '@meetico/mappify/ui/page-title/page-title.component'
import MapsTable from '@meetico/mappify/tables/maps.table'
import InvitationDialog from '@meetico/mappify/components/dialogs/invitations.component'
import { StoreContext } from '@meetico/mappify/context/store.context'

const Maps = ({ history }) => {    
    const [invitations, setInvitations] = useState([])
    const [invitationDialog, setInvitationDialog] = useState(false)
    const { auth: { isAuth }, apiService, dataset } = useContext(StoreContext)

    const fetchInvitations = useCallback(() => apiService('/pending-invitations','invitations','get'),[apiService])

    useEffect(() => {
        if(isAuth && !dataset.invitations) fetchInvitations()        
        if(isAuth && dataset.invitations && (invitations !==dataset.invitations.pending_invitations)) setInvitations(dataset.invitations.pending_invitations)
    },[isAuth,dataset.invitations,fetchInvitations,invitations]) 

    const acceptRequest = async invitation  => {
        if(await apiService('/invitation','','put',{ id: invitation.id, status: 'accepted'})){
            setInvitationDialog(false)
            setTimeout(() => fetchInvitations(), 500)            
        }        
    }
    const rejectRequest = async invitation  => {
        if(await apiService('/invitation','','put',{ id: invitation.id, status: 'rejected'})){
            setInvitationDialog(false)
            fetchInvitations()
        }                
    }

    const closeInvitationsDialog = () => setInvitationDialog(false)

    const createMap = () => {
        history.push('/map/create-new')
    }     

    return (   
        <Fragment>
            <Grid container spacing={2}> 
                <Grid item xs={12}>
                    <PageTitle title="Le tue Mappe" subtitle="Gestisci le mappe su cui stai lavorando" />
                </Grid>           
                <Grid item xs={12}>
                    <Grid container justify="flex-end" spacing={3}>
                        {invitations.length>0 ? (
                            <Grid item>
                                <Badge badgeContent={invitations.length} color="error">
                                    <Button variant={"outlined"} onClick={() => setInvitationDialog(true)} startIcon={<WarningIcon color="error" />}>
                                        Richieste di Condivisione
                                    </Button>
                                </Badge>
                            </Grid>                        
                        ) : null }
                        <Grid item>
                            <Button variant={"outlined"} onClick={createMap}>Aggiungi nuova mappa</Button>
                        </Grid>
                    </Grid>                        
                </Grid>
                <Grid item xs={12}>    
                    <Paper>
                        <MapsTable />
                    </Paper>
                </Grid>
            </Grid>
            {invitationDialog && <InvitationDialog open={invitationDialog} acceptRequest={acceptRequest} rejectRequest={rejectRequest} invitations={invitations} closeDialog={closeInvitationsDialog} /> }
        </Fragment>         
    )
}

export default withRouter(Maps)