import React, { useContext } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import {createBrowserHistory} from "history";
import ROUTES from './routes'
import { StoreContext } from '../context/store.context'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppLayout from '@meetico/mappify/layouts/app.layout'
import ServerDown from '@meetico/mappify/components/ui/server-down/server-down.component'
import PageNotFound from '../pages/404/404.pages'

const history = createBrowserHistory();

const Routing = React.memo(() => {
    
    const { auth: { isAuth, isChecking, serverDown } } = useContext(StoreContext)
    
    if(serverDown){
        return (
            <Container component="main" maxWidth="sm">
                <ServerDown />
            </Container>
        )
    }
    if(isChecking){
        return (
            <Container component="main" maxWidth="xs">
                <CircularProgress size={200} />
            </Container>
        )
    }

    const routes = ROUTES
        .filter(r => r.visibility === (isAuth ? 'auth' : 'public'))
        .map(r => {
            let DynamicComponent = r.component
            return (
                <Route 
                    key={r.name} 
                    path={r.routePath} 
                    exact
                    render={(props) => (
                        <AppLayout layout={r.layout} breadcrumb={r.breadcrumb} toolbarPosition={r.toolbarPosition}>
                            <DynamicComponent layout={r.layout} user_type={r.user_type ? r.user_type : null} {...props}/>
                        </AppLayout> 
                    )}
                />
            );
        })

    return (
        <Router history={history}>
            <Switch>
                {routes}
                {isAuth && <Redirect to={'/'} />}
                <Route><PageNotFound /></Route>
            </Switch>
        </Router>
    )
})

export default Routing