import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {SITE_MAP} from "@meetico/mappify/core/config";
import { StoreContext } from '@meetico/mappify/context/store.context'
import Logo from '@meetico/mappify/core/theme/images/logo.svg'

const useStyles = makeStyles((theme) => ({
    menu: {
        width: 400,
        height: '100%',
        background: theme.palette.primary.main
    },
    scrollable: {
        flexGrow: 1,
        //paddingBottom: '150px!important',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#3A3C48'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5f5f5',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'white'
        }
    },
    whiteText: {
        color: 'white'
    },
    dividerText: {
        color: 'white',
        background: theme.palette.primary.main,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        width: 'fit-content',
        padding: '0 20px'
    },
    whiteDivider: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        position: 'relative',
        margin: theme.spacing(4)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: 'white'
    },
    expansionPanel: {
        background: 'transparent',
        color: 'white',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        }
    },
    menuIcon: {
        color: 'white',
        marginRight: theme.spacing(2)
    },
    list: {
        width: '100%',
        '& .MuiListItem-root': {
            width: '100%',
            background: '#454752',
            borderBottom: '2px solid #3A3C48',
            borderTop: '2px solid #3A3C48',
        }
    }
}));

const DrawerComponent = ({open, toggleDrawer, location: {pathname}, history}) => {
    const classes = useStyles();
    const {auth: { user, logout } } = useContext(StoreContext)
    const [expanded, setExpanded] = useState();
    const handleChange = (panelN) => (event, isExpanded) => {
        event.stopPropagation();
        setExpanded(isExpanded ? panelN : null);
    };    
    const isExpanded = (path) => expanded === path

    useEffect(() => {
        SITE_MAP.reduce(({expansion}) => {
            if (expansion) {
                const open = expansion.find(({path}) => pathname.search(path) !== -1);
                if (!open) {
                    setExpanded(null);
                } else if (expanded !== open.path) {
                    setExpanded(open.path);
                }
            }
            return expansion;
        });
    }, [pathname,expanded])

    return (
        <SwipeableDrawer
            open={open} variant={"temporary"}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}>
            <Grid container direction={"column"}
                  className={classes.menu} role="presentation" onClick={() => toggleDrawer(false)}
                  onKeyDown={() => toggleDrawer(false)} spacing={2} style={{margin: 0}} wrap={"nowrap"}>
                <Grid item container direction={"column"} alignContent={"center"}>
                    <Grid item>
                        <img style={{marginTop: '1rem'}} src={Logo} width="35" alt="logo" />                        
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant={"subtitle2"} className={classes.whiteText} align={"center"}>stai navigando come</Typography>
                </Grid>
                <Grid item>
                    <Divider component={'div'} className={classes.whiteDivider}>
                        <Typography className={classes.dividerText} align={"center"} onClick={() => history.push('/profilo/')}>
                            {user && `${user.name} ${user.surname}`}
                        </Typography>
                    </Divider>
                </Grid>
                <Grid item xs className={classes.scrollable} container direction={"column"} justify={"space-between"} wrap={"nowrap"}>
                    <Grid item>
                        {SITE_MAP.map(({label, path, icon, expansion}, index) => (
                            <Grid container direction={"column"} wrap={"nowrap"} key={index}>
                                <Grid item>
                                    <Grid container direction={"column"}>
                                        {expansion.map(({label, path, icon, items}, index) => (
                                            <Grid item key={index}>
                                                <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded(path)} onChange={handleChange(path)}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<Icon className={classes.whiteText}>expand_more</Icon>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Icon className={classes.menuIcon}>{icon}</Icon>
                                                        <Typography color={isExpanded(path) ? 'primary' : 'inherit'} variant={"button"}>
                                                            {label}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <List component="nav" className={classes.list} disablePadding>
                                                            {items.map(({label, link}, index) => (
                                                                <Link component={RouterLink} to={link}
                                                                      color={"inherit"} key={index}>
                                                                    <ListItem button>
                                                                        <ListItemText primary={label}/>
                                                                    </ListItem>
                                                                </Link>
                                                            ))}
                                                        </List>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        ))}
                                        {!expansion.length &&
                                        <ExpansionPanelSummary id="panel1bh-header" onClick={() => history.push(path)}>
                                            <Icon className={classes.menuIcon}>{icon}</Icon>
                                            <Typography className={classes.whiteText} variant={"button"}>
                                                {label}
                                            </Typography>
                                        </ExpansionPanelSummary>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item className={classes.lastSection}>
                        <Divider component={'div'} className={classes.whiteDivider}>
                            <Typography className={classes.dividerText} align={"center"}>
                                Account
                            </Typography>
                        </Divider>
                        <Grid container direction={"column"} spacing={2} style={{padding: '0 24px', marginBottom: '2rem'}}>                            
                            <Grid item container justify={"center"}>                                
                                <Grid item>
                                    <Button onClick={() => { logout(); history.push('/') }}>
                                        <Icon color={"error"}>power_settings_new</Icon>
                                        <Typography className={classes.whiteText}>
                                            logout
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SwipeableDrawer>
    );
};

DrawerComponent.defaultProps = {
    open: false,
    toggleDrawer: () => console.warn('Missing toggleDrawer function')
};

DrawerComponent.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default withRouter(DrawerComponent);
