import randomColor from 'randomcolor'

const MapService = {
    layers: [],
    getLayers: function(){
        return this.layers
    },
    setLayers: function(layers){
        this.layers = layers        
    },
    createMapDraw: async draw => {
        const type = draw.layerType
        const color = randomColor()
        let position, feature        
        switch(type){
            case 'marker':
                position = [draw.layer._latlng.lat, draw.layer._latlng.lng]
                feature = { type, position, color, zIndex: draw.layer._zIndex }                
                break
            case 'rectangle':
                position = []
                await draw.layer._latlngs[0].map(latlng => position.push([latlng.lat,latlng.lng]))
                feature = { type, position, color, zIndex: draw.layer._zIndex }                
                break
            case 'polygon':
                position = []
                await draw.layer._latlngs[0].map(latlng => position.push([latlng.lat,latlng.lng]))
                feature = { type, position, color, zIndex: draw.layer._zIndex }                
                break
            case 'circle':
                position = [draw.layer._latlng.lat, draw.layer._latlng.lng]
                feature = { type, position, color, radius: draw.layer._mRadius, zIndex: draw.layer._zIndex }                
                break
            case 'circlemarker':
                position = [draw.layer._latlng.lat, draw.layer._latlng.lng]
                feature = { type, position, color, radius: draw.layer._radius, zIndex: draw.layer._zIndex }                
                break
            case 'polyline':
                position = []
                await draw.layer._latlngs.map(latlng => position.push([latlng.lat,latlng.lng]))
                feature = { type, position, color, zIndex: draw.layer._zIndex }                
                break
            default: 
                break;
        }                       
        return feature
    },
    updateMapDraw: async function(layer_uuid, uuid, updatedFeature){        
        let position
        let feature = await this.layers.find(l => l.uuid===layer_uuid).features.find(f => f.uuid===uuid)        
        if(feature){                        
            switch(feature.type){
                case 'marker':
                    position = [updatedFeature._latlng.lat, updatedFeature._latlng.lng]
                    feature = {...feature, position }
                    break
                case 'rectangle':
                    position = []
                    await updatedFeature._latlngs[0].map(latlng => position.push([latlng.lat,latlng.lng]))
                    feature = {...feature, position }
                    break
                case 'polygon':
                    position = []
                    await updatedFeature._latlngs[0].map(latlng => position.push([latlng.lat,latlng.lng]))
                    feature = {...feature, position }     
                    break
                case 'circle':
                    position = [updatedFeature._latlng.lat, updatedFeature._latlng.lng]
                    feature = {...feature, position, radius: updatedFeature._mRadius }
                    break
                case 'circlemarker':
                    position = [updatedFeature._latlng.lat, updatedFeature._latlng.lng]
                    feature = {...feature, position}
                    break
                case 'polyline':
                    position = []
                    await updatedFeature._latlngs.map(latlng => position.push([latlng.lat,latlng.lng]))
                    feature = {...feature, position }
                    break
                default: 
                    break;
            } 
        }
        return feature
    }
}

export default MapService