import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles'
import DefaultLayout from "@meetico/mappify/layouts/default.layout";
import ClearLayout from "@meetico/mappify/layouts/clear.layout";
import MapLayout from "@meetico/mappify/layouts/map.layout";
import bgPattern from '@meetico/mappify/core/theme/images/giscake-background.jpg'

const useStyles = makeStyles(theme => ({
    gridContainer:{
        position: 'relative',
        minHeight: '100vh',
        backgroundImage: `url(${bgPattern})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
}))

const AppLayout = ({layout, toolbarPosition, breadcrumb, children}) => {
    window.scrollTo(0,0)
    const classes = useStyles()
    switch (layout) {
        case "clear":
            return (
                <ClearLayout toolbarPosition={toolbarPosition}>
                    {children}
                </ClearLayout>
            );        
        case "default":
            return (
                <DefaultLayout breadcrumb={breadcrumb} toolbarPosition={toolbarPosition}>
                    {children}
                </DefaultLayout>
            );
        case 'map':
            return (
                <MapLayout toolbarPosition={toolbarPosition}>
                    {children}
                </MapLayout>
            ); 
        default:
            return (
                <Grid container direction={"column"} justify={"center"} className={classes.gridContainer}>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            );
    }
};

AppLayout.defaultProps = {
    layout: 'default',
    toolbarPosition: 'absolute'
};

AppLayout.propTypes = {
    layout: PropTypes.oneOf(['default', 'clear', 'map','no-toolbar']),
    toolbarPosition: PropTypes.string
};


export default AppLayout;
