import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        height: 'auto',
        maxHeight: 200,
        overflowX: 'hidden',
        overflowY: 'auto',
        flexWrap: 'nowrap', 
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-track':{
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.6)'
        }
    },
    searchRow:{
        marginTop: 0,
        marginBottom: 0
    },
    resultsCounter:{
        fontSize: 14,
        color: '#B9B9B9',
        marginBottom: 15
    },
    resultLabel: {
        fontSize: 14
    },
    resultType:{
        color: '#B9B9B9',
    }
}))

const MapLayersControlsSearchResults = ({ results, onFeatureClick, openWmsTab, openNotesTab }) => {
    const classes = useStyles()    

    const handleRowClick = (row) => {
        if(row.type!=='wms' && row.type!=='note') onFeatureClick(row, row.layer)
        else if(row.type==='wms') openWmsTab()
        else if(row.type==='note') openNotesTab(row)
        return false
    }

    return (        
        <Box p={2}>
            <Typography className={classes.resultsCounter}>{results.length} results for this search</Typography>
            <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.searchContainer}>
                {results.map((row,index) => (
                    <Grid key={index} item xs={12} container direction="row" justify="flex-start" alignItems="center" spacing={2} onClick={() => handleRowClick(row)} className={classes.searchRow}>
                        <Grid item>
                            <img src={require(`@meetico/mappify/core/theme/images/map-icons/ico-${row.type}.png`)} alt={`${row.type} icon`} />                            
                        </Grid>
                        <Grid item>
                            {(row.type!=='wms' && row.type!=='note') && <Typography className={classes.resultLabel}>{row.name ? row.name : `${row.type} #${row.uuid}`}</Typography>}
                            
                            {(row.type==='wms' && row.name.length<=15) && (
                                <Typography className={classes.resultLabel}>{row.name}</Typography>
                            )}
                            {(row.type==='wms' && row.name.length>15) && (
                                <Tooltip placement="top" title={row.name}>
                                    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 150}}>                                
                                        <Typography noWrap className={classes.resultLabel}>{row.name}</Typography>
                                    </div>
                                </Tooltip>
                            )}  

                            {(row.type==='note' && row.note.length<=15) && (
                                <Typography className={classes.resultLabel}>{row.note}</Typography>   
                            )}
                            {(row.type==='note' && row.note.length>15) && (
                                <Tooltip placement="top" title={row.note}>
                                    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 150}}>                                
                                        <Typography noWrap className={classes.resultLabel}>{row.note}</Typography>
                                    </div>
                                </Tooltip>
                            )}
                            <small className={classes.resultType}>{row.type}</small>
                        </Grid>
                    </Grid>                                
                ))}            
            </Grid>
        </Box>
    )
}

export default MapLayersControlsSearchResults