import React from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ShowIcon from "@material-ui/icons/Visibility";
import HideIcon from "@material-ui/icons/VisibilityOff";
import GestureIcon from "@material-ui/icons/Gesture";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    visibilityIcon: {
        marginRight: 9,
        color: theme.palette.common.black,
    },
    layerName: {
        color: theme.palette.common.black,
    },
    actionsIcon: {
        color: theme.palette.common.black,
    },
    selectedLayerBg: {
        background: theme.palette.grey[200],
    },
    editModeEnable: {
        color: theme.palette.secondary.main,
    },
    editModeDisabled: {
        color: theme.palette.grey["500"],
    },
}));

const MapLayersListItem = ({
    layer,
    selectedLayer,
    _onSetLayerSelected,
    _setLayerShow,
    _setLayerHide,
    _onEditLayer,
    _onDeleteLayer,
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={
                selectedLayer && layer.uuid === selectedLayer.uuid
                    ? classes.selectedLayerBg
                    : ""
            }
        >
            <Grid item>
                <Grid container alignItems="center" justify="flex-start">
                    <Grid item>
                        <Tooltip placement="top" title={"Click to side layer"}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    layer.active
                                        ? _setLayerHide(layer.uuid)
                                        : _setLayerShow(layer.uuid);
                                }}
                                className={classes.visibilityIcon}
                            >
                                {layer.active ? <ShowIcon /> : <HideIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            placement="top"
                            title={"Clicca per lavorare su questo layer"}
                        >
                            <IconButton
                                size="small"
                                onClick={async (e) => {
                                    if (!layer.active) {
                                        await _setLayerShow(layer.uuid);                                     
                                    }
                                    _onSetLayerSelected(layer);
                                    e.stopPropagation();
                                }}
                            >
                                <GestureIcon
                                    className={
                                        selectedLayer &&
                                        layer.uuid === selectedLayer.uuid
                                            ? classes.editModeEnable
                                            : classes.editModeDisabled
                                    }
                                />
                            </IconButton>
                        </Tooltip>                        
                    </Grid>
                    <Grid item>
                        {layer.name.length < 15 && (
                            <Typography
                                variant="body2"
                                className={classes.layerName}
                            >
                                {layer.name}
                            </Typography>
                        )}
                        {layer.name.length >= 15 && (
                            <Tooltip placement="top" title={layer.name}>
                                <div
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: 150,
                                    }}
                                >
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        className={classes.layerName}
                                    >
                                        {layer.name}
                                    </Typography>
                                </div>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Tooltip placement="top" title={"Clicca per modificare layer"}>
                    <IconButton
                        size="small"
                        onClick={() => _onEditLayer(layer)}
                        className={classes.actionsIcon}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip placement="top" title={"Clicca per eliminare layer"}>
                    <IconButton
                        size="small"
                        onClick={() => _onDeleteLayer(layer)}
                        className={classes.actionsIcon}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default MapLayersListItem;
