import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ShowIcon from '@material-ui/icons/Visibility'
import HideIcon from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    row:{
        paddingTop: theme.spacing(1)
    },
    visibilityIcon: {
        marginRight: 9,
        color: theme.palette.common.black
    },
    actionsIcon:{
        color: theme.palette.common.black
    }
}))

const MapNotesListItem = ({ note, _onToggleNote, _onEdit, _onDelete }) => {
    const classes = useStyles()    
    return (                        
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.row}>
            <Grid item>
                <Grid container alignItems="center" justify="flex-start">
                    <Grid item>                        
                        <Tooltip placement="top" title={"Click to toggle Note"}>
                            <IconButton size="small" onClick={() => { _onToggleNote(note.uuid) }} className={classes.visibilityIcon}>
                                {note.active ? <ShowIcon /> : <HideIcon />}
                            </IconButton>
                        </Tooltip>                        
                    </Grid>
                    <Grid item>
                        {(note.note.length < 15) && <Typography variant="body2">{note.note}</Typography>}
                        {(note.note.length >= 15) && (
                            <Tooltip placement="top" title={note.note}>
                                <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 150}}>                                
                                    <Typography noWrap variant="body2">{note.note}</Typography>
                                </div>
                            </Tooltip>
                        )}                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Tooltip placement="top" title="Edit Note">
                    <IconButton size="small" onClick={() => _onEdit(note)} className={classes.actionsIcon}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Delete Note">
                    <IconButton size="small" onClick={() => _onDelete(note)} className={classes.actionsIcon}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )    
}

export default MapNotesListItem