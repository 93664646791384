import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const PageTitle = ({title, subtitle}) => {    
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                <Typography variant={"h4"} align={"center"} style={{fontWeight: 500}}>{title}</Typography>
            </Grid>
            {subtitle && (
                <Grid item>
                    <Typography variant={"body1"} align={"center"}>{subtitle}</Typography>
                </Grid>
            )}            
        </Grid>
    );
};
PageTitle.defaultProps = {
    title: '',
    subtitle: ''
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
};

export default PageTitle;
