import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import UserDropdown from '@meetico/mappify/ui/user-dropdown/user-dropdown.component'

import makeStyles from "@material-ui/core/styles/makeStyles";

import Logo from '@meetico/mappify/core/theme/images/logo.svg'

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    whiteText: {
        color: 'white'
    },
    appBar: {
        zIndex: `${theme.zIndex.drawer + 2}!important`,        
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '98%',
        top: 10,
        left: 15,
        borderRadius: 40        
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    }
}));

const ToolbarComponent = ({toggleDrawer, position, history }) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openUserDropdown = (event) => { setAnchorEl(event.currentTarget) }
    const closeUserDropdown = (e) => { setAnchorEl(null) }    
    
    return (
        <div className={classes.grow}>
            <AppBar position={position} className={classes.appBar}>
                <Toolbar>
                    <Button onClick={() => history.push('/home')} color={"inherit"} className={classes.logo}>
                        <img src={Logo} width="30" alt="logo" />
                    </Button>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                                <Icon fontSize={"large"}>menu</Icon>
                            </IconButton>                            
                        </Grid>
                        <Grid item>
                            <IconButton aria-controls="toolbar-menu" aria-haspopup="true" className={classes.whiteText} onClick={openUserDropdown}>
                                <Icon fontSize="large">account_circle</Icon>                                
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <UserDropdown anchorEl={anchorEl} handleClose={closeUserDropdown} />
        </div>
    );
};

ToolbarComponent.defaultProps = {
    toggleDrawer: () => console.error('Missing toggleDrawer function'),
    position: 'absolute'
};

ToolbarComponent.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    position: PropTypes.string
};

export default withRouter(ToolbarComponent);
