import { useReducer, useCallback } from 'react'
import { ENDPOINT } from '../config'
import axios from 'axios'
import { toast } from 'react-toastify';

const initState = {
    dataset: {},
    fetching: false,
    loading: false    
}

const apiReducer = (state, action) => {
    let dataset = {...state.dataset}
    switch (action.type) {
        case 'FETCHING_DATASET':
            return {...state, fetching: true, serverDown:false}
        case 'SET_DATASET':            
            dataset[action.entity] = action.data            
            return {...state, dataset, fetching: false, serverDown:false }
        case 'FETCHING_ERROR':
            return {...state, fetching: false, serverDown:false}
        case 'START_LOADING':
            return { ...state, loading: true, serverDown:false }
        case 'END_LOADING':        
            return { ...state, loading: false, serverDown:false }
        case 'CLEAR_DATASET':            
            dataset[action.entity] = null
            return {...state, dataset, serverDown:false}
        
        default:
            throw new Error('Should not get here!')
    }
}

const useApi = () => {
    const [state, dispatch] = useReducer(apiReducer,initState)

    const apiService = useCallback(async(url, entity, method = 'get', body = {}) => {
        try{
            if(method==='get') dispatch({ type: 'FETCHING_DATASET' })
            else  dispatch({ type: 'START_LOADING' })
            const { data } = await axios[method](`${ENDPOINT}${url}`,body)
            if(method==='get') dispatch({ type: 'SET_DATASET', entity, data })
            else{                
                toast.success('Operazione completata con successo')
                dispatch({ type: 'END_LOADING' })            
                return true
            }
        }catch(error){            
            if(method==='get') dispatch({ type: 'FETCHING_ERROR' })
            else  dispatch({ type: 'END_LOADING' })            
            let message
            if(error.message) message = error.message
            else if(error.response && error.response.data && error.response.data.message) message = error.response.data.message
            else message = 'Errore durante l\'esecuzione dell\'operazione!'
            toast.error(message)
            return false
        }
    }, [])

    const downloadFile = useCallback(async filename => {
        try{
            const { data } = await axios.request({
                url: `${ENDPOINT}/donwload-file/${filename}`,        
                method: 'GET',
                responseType: 'blob'
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }catch(error){
            let message
            if(error.message) message = error.message
            else if(error.response && error.response.data && error.response.data.message) message = error.response.data.message
            else message = 'Errore durante l\'esecuzione dell\'operazione!'
            toast.error(message)
            return false
        }
    },[])
    
    const clearDataset = useCallback(entity => {
        dispatch({ type: 'CLEAR_DATASET', entity })
    }, [])
    
    return {
        dataset: state.dataset,
        isFetchingData: state.fetching,
        isLoadingData: state.loading,        
        clearDataset,
        apiService,
        downloadFile
    }
}

export default useApi