import React, { Fragment, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StoreContext } from './context/store.context'
import './styles/Toast.css';
import Router from './routes'

const App = () => {
  const { auth: { chekUserToken } } = useContext(StoreContext)
  useEffect(() => {
    chekUserToken()
  }, [chekUserToken])
  return (
    <Fragment>
      <Router />
      <ToastContainer closeOnClick={true} autoClose={5000} position={toast.POSITION.TOP_RIGHT} />
    </Fragment>
  )
}

export default App;