export default [ 
    //PUBLIC ROUTES       
    {
        component: require('../pages/reset-password/reset-password.page').default,
        routePath: '/reset-password/:token?',
        name: 'reset-password',
        layout: 'no-toolbar',
        toolbarPosition: 'fixed',
        visibility: 'public',
        breadcrumb: false
    },
    {
        component: require('../pages/login/login.page').default,
        routePath: '/',
        name: 'login',
        layout: 'no-toolbar',
        toolbarPosition: 'fixed',
        visibility: 'public',
        breadcrumb: false
    },
    {
        component: require('../pages/sign-up/sign-up.page').default,
        routePath: '/sign-up',
        name: 'sign-up',
        layout: 'no-toolbar',
        toolbarPosition: 'fixed',
        visibility: 'public',
        breadcrumb: false
    },
    {
        component: require('../pages/privacy-policy/privacy-policy.page').default,
        routePath: '/privacy-policy',
        name: 'privacy-policy',
        layout: 'no-toolbar',
        toolbarPosition: 'fixed',
        visibility: 'public',
        breadcrumb: false
    },
    //AUTHENTICATED ROUTES    
    
    {
        component: require('../pages/map-form/map-form.page').default,
        routePath: '/map/:map_id?/edit',       
        name: 'edit-map',
        layout: 'default',
        toolbarPosition: 'fixed',
        visibility: 'auth',
        breadcrumb: false
    },
    {
        component: require('../pages/map-form/map-form.page').default,
        routePath: '/map/create-new',       
        name: 'new-map',
        layout: 'default',
        toolbarPosition: 'fixed',
        visibility: 'auth',
        breadcrumb: false
    },
    {
        component: require('../pages/map/map.page').default,
        routePath: '/map/:map_id?',
        name: 'map',
        layout: 'map',
        toolbarPosition: 'fixed',
        visibility: 'auth',
        breadcrumb: false
    },
    {
        component: require('../pages/maps/maps.page').default,
        routePath: '/',
        name: 'maps',
        layout: 'default',
        toolbarPosition: 'fixed',
        visibility: 'auth',
        breadcrumb: false
    }
]