import React from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import PageTitle from '@meetico/mappify/ui/page-title/page-title.component'
import MapForm from '@meetico/mappify/forms/map.form'

const MapFormPage = ({ match, history }) => {        
    const map_id = match.params.map_id      
    const action = map_id ? 'modifica' : 'creazione'
    const pageSubtitle = map_id ? 'Modifica mappa' : 'Crea una nuova mappa'    
    return (     
        <Grid container justify="center" spacing={4}> 
            <Grid item xs={12}>
                <PageTitle title="Le tue mappe" subtitle={pageSubtitle} />
            </Grid>                                           
            <Grid item xs={12}>
                <Card elevation={5}>
                    <CardContent>
                        <Grid container justify="flex-start" spacing={4}>
                            <Grid item>
                                <Button variant={"text"} onClick={() => history.goBack()}>
                                    <Icon>keyboard_backspace</Icon> Indietro
                                </Button>
                            </Grid>                
                        </Grid>
                        <Container maxWidth="sm">
                            <MapForm map_id={map_id} action={action} />                        
                        </Container>
                    </CardContent>
                </Card>                                              
            </Grid>
        </Grid>        
    )
}

export default withRouter(MapFormPage)