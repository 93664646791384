import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import StoreProvider from './context/store.context'
import * as serviceWorker from './serviceWorker';
import theme from './theme/default.theme'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <StoreProvider>
            <App />
        </StoreProvider>
    </ThemeProvider>, 
    document.getElementById('root')
);
serviceWorker.unregister();
