import React, { useCallback, useMemo, useState } from 'react' //useContext
import { withRouter, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../../theme/images/logo.svg'
// import { StoreContext } from '../../context/store.context.js'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem 1.5rem'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ResetPassword = () => {
    // const {auth: { login } } = useContext(StoreContext)        
    const classes = useStyles();
    const { token } = useParams();
    const [email, setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [confPassword,setConfPassword] = useState('')

    const requestResetPassowrdHandler = useCallback(() => {        
    },[])
    const resetPassowrdHandler = useCallback(() => {        
    },[])

    const renderPasswordsMismatch = useMemo(() =>{        
        if(password && password.trim()!=='' && confPassword && confPassword.trim()!=='' && password!==confPassword){
            return (
                <Grid container>
                    <Grid item>
                        <Typography variant="body1">Le due password non combaciano</Typography>
                    </Grid>
                </Grid>
            )
        } 
        return null
    },[password,confPassword])

    const isRequestResetPassDisabled = useCallback(() => {
        return !email || (email && email.trim()==='')
    },[email])
    
    const isResetPassDisabled = useCallback(() => {
        return (!password || !confPassword || (password && password.trim()==='') || (confPassword && confPassword.trim()===''))
    },[password,confPassword])
    
    const renderRequestResetPasswordForm = useMemo(() => {
        if(!token){
            return (
                <div>                    
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="La tua E-mail"
                        name="email"
                        value={email}
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                    />                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isRequestResetPassDisabled()}
                        onClick={requestResetPassowrdHandler}
                    >
                        Richiedi Reset della Password
                    </Button>
                </div>
            )
        }        

        return (
            <div>                
                <TextField
                    variant="outlined"
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    id="password"
                    label="Inserisci la nuova password"
                    name="password"                    
                    value={password}
                    autoFocus
                    onChange={(e) => setPassword(e.target.value)}
                /> 
                <TextField
                    variant="outlined"
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    id="confPassword"
                    label="Conferma la nuova password"
                    name="confPassword"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                />
                {renderPasswordsMismatch}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isResetPassDisabled()}
                    onClick={resetPassowrdHandler}
                >
                    Imposta nuova password
                </Button>
            </div>
        )
    },[token, email, password, confPassword, classes,renderPasswordsMismatch,requestResetPassowrdHandler, isRequestResetPassDisabled, isResetPassDisabled,resetPassowrdHandler])

    return (
        <Container component="main" maxWidth="xs">        
            <Paper className={classes.paper}>
                <img src={Logo} width="35" alt="logo" />
                <Typography component="h1" variant="h5">
                    Reimposta la password
                </Typography>
                <form className={classes.form} noValidate>
                    {renderRequestResetPasswordForm}                    
                    <Grid container>
                        <Grid item xs align="center">
                            <Link href="/" variant="body2">
                                Ricordi le credenziali? Torna al Login
                            </Link>
                        </Grid>                        
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Typography variant="body2" className={classes.bottomText} align="center">
                    {`Mappify - Copyright © Meetico LTD ${new Date().getFullYear()}.`}
                </Typography>
            </Box>
        </Container>
    );
}

export default withRouter(ResetPassword)