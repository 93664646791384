import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { socket } from '@meetico/mappify/layouts/map.layout'
import { makeStyles } from '@material-ui/core/styles'
import { HuePicker } from 'react-color';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        width: '100%'        
    },
    sectionTitle:{
        fontSize: 16,
        fontWeight: 'bold'
    },
    editIcon: {
        color: theme.palette.common.black
    },
    readOnlyField: {
        background: '#f9f9f9',
        borderRadius: 15,
        padding: '0.5rem 1rem',
        marginBottom: '0.5rem'
    },
    colorPreview:{
        width: 25,
        height: 25
    },
    colorPreviewText:{
        fontSize: 12
    },
    appearanceContainer: { 
        border:'1px solid #ccc', 
        borderRadius: 15, 
        margin:0,
        width: '100%',
        padding: '0.2rem 0.45rem',
    }
}))

const InfoAppearance = ({ map_id, feature }) => {

    const classes = useStyles()
    const [inputState, setInputState] = useState('read')
    const [appearance, setAppearance] = useState('#000')
    const [form, setForm] = useState({
        uuid: feature.uuid,
        color: ''
    })

    const closeEdit = () => {
        setForm(appearance)
        setInputState('read')
    }

    const handleChange = (name, value) => {        
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const info = { map_id, layer_uuid: feature.layer_uuid, uuid:feature.uuid, name: form.name, description: form.description, color: form.color}        
        socket.emit('update-feature-info', info)
    }

    useEffect(() => {        
        socket.emit('get-feature-info', { map_id, feature_uuid: feature.uuid })        

        socket.on('feature-info-update', data => {            
            if(data.feature_uuid===feature.uuid){
                setForm(data)
                setAppearance(data)
            }            
        })

        socket.on('update-feature-success', () => {
            setInputState('read')
        })
        return () => {
            socket.off('feature-info-update')
            socket.off('update-feature-success')
        }
    },[map_id, feature.uuid]) 

    return (
        <Grid container direction="column" spacing={1} justify="center" className={classes.root}>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">                    
                <Grid item>
                    <Typography className={classes.sectionTitle}>Feature appearance</Typography>
                </Grid>
                <Grid item>
                    <IconButton className={classes.editIcon} edge="end" onClick={() => inputState==='read' ? setInputState('edit') : closeEdit()}>
                        {inputState==='read' ? <EditIcon /> : <CloseIcon />}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {inputState!=='edit' ? (
                    <div className={classes.readOnlyField}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item>
                                <Box boxShadow={2} className={classes.colorPreview} style={{backgroundColor: form.color}} />
                            </Grid>
                            <Grid item>
                                <div className={classes.colorPreviewText} dangerouslySetInnerHTML={{ __html: form.color ? `Feature color: <b>${form.color}</b>` : 'Click on edit button to choose feature appearance' }} />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2} className={classes.appearanceContainer}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Box boxShadow={2} className={classes.colorPreview} style={{backgroundColor: form.color}} />
                                </Grid>
                                <Grid item>
                                    <HuePicker 
                                        color={form.color}
                                        onChange={(color, event) => handleChange('color',color.hex)}
                                        direction="horizontal"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Grid item>
                                    <Tooltip placement="top" title="Save">
                                        <IconButton className={classes.editIcon} onClick={handleSubmit}><SaveIcon /></IconButton>
                                    </Tooltip>                            
                                </Grid>
                                <Grid item>
                                    <Tooltip placement="top" title="Close">
                                        <IconButton className={classes.editIcon} edge="end" onClick={closeEdit}><CloseIcon /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>                    
                )}
            </Grid>
        </Grid>
    )
}

export default InfoAppearance