import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: 'Poppins',
    },
    h2: {
      fontFamily: 'Poppins',
    },
    h3: {
      fontFamily: 'Poppins',
    },
    h4: {
      fontFamily: 'Poppins',
    },
    h5: {
      fontFamily: 'Poppins',
    },
    h6: {
      fontFamily: 'Poppins',
    },
    body1: {
      fontFamily: 'Rubik',
    },
    body2: {
      fontFamily: 'Rubik',
    },   
    subtitle1: {
      fontFamily: 'Rubik',
    },
    subtitle2: {
      fontFamily: 'Rubik',
    }, 
    button: {
      fontFamily: 'Rubik',
    },
    caption: {
      fontFamily: 'Rubik',
    },
    overline: {
      fontFamily: 'Rubik',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Rubik','Poppins'],
      },
    },
  },
  palette: {
    primary: {
      main: '#0A1A2D',
      light: '#fff'
    },
    secondary: {
      main: "#80A47B",
    },
    success:{
      main: "#80A47B"
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;