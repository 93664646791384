import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const FeatureFileForm = ({ file, name, handleFileChange, handleNameChange, uploadFileHandler }) => {

    const triggerFileInput = () => {
        document.getElementById('attachment-upload-input').click()
    }

    return (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <TextField 
                    fullWidth
                    label="Nome File" 
                    onChange={handleNameChange}
                    onFocus={handleNameChange}
                    value={name}                            
                    color="secondary"                                                       
                />
            </Grid>
            <Grid item xs={12}>
                <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.zip"
                    style={{display:'none'}}
                    id="attachment-upload-input"
                    onChange={handleFileChange}
                />
                <TextField 
                    fullWidth
                    label="Seleziona File" 
                    onClick={triggerFileInput} 
                    value={file.name}  
                    helperText="File accettati: .jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.zip"                             
                    color="secondary"
                />
            </Grid>            
        </Grid>
    )
}

export default FeatureFileForm