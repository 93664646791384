import React from 'react';
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    breadcrumb: {
        background: '#FAFAFA',
        paddingTop: 15, 
        paddingBottom: 15
    }
}));
const BreadcrumbComponent = ({ location: {pathname} }) => {
    const classes = useStyles();
    let breadcrumb = pathname.split('/').filter(b => /^[a-zA-Z\s]+$/.test(b.replace(/-/g, " ")))    
    return (
        <Grid container alignItems={"center"} style={{margin: 0 }}>
            <Grid item xs={12} className={classes.breadcrumb}>
                <Grid container spacing={1}>
                    {breadcrumb.map((pathName, index) => {
                        const isLast = index === breadcrumb.length - 1;
                        return (
                            <Grid item key={index} style={index===0 ? {paddingLeft:15} : {}}>
                                <Typography variant={"body1"} style={{textTransform: 'capitalize', fontWeight: isLast && index !== 0 ? 'bold' : 'inherit'}}>
                                    {`${pathName.replace(/-/g,' ')} ${!isLast ? '/' : ''}`}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>            
        </Grid>
    );
};

BreadcrumbComponent.propTypes = {};

export default withRouter(BreadcrumbComponent);
