import React, { useState, useEffect, useContext, useCallback } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress';
import { StoreContext } from '@meetico/mappify/context/store.context'
import useDebounce from '@meetico/mappify/core/hooks/useDebounce.hook'


const useStyles = makeStyles(theme => ({
    textField: {
      width: '100%'      
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

const requiredFields = ['name','description','lat','long','zoom']

const MapForm = ({ map_id, action, history }) => {
    const { auth: { isAuth }, isLoading, apiService, dataset, clearDataset } = useContext(StoreContext)    
    const [form, setForm] = useState({
        name: '',
        description:'',        
        lat: null,
        long: null,
        address: null,
        zoom: 13
    })    
    const [errors, setErrors] = useState({})
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    
    useEffect(() => {        
        if(dataset[`map_${map_id}`]) setForm(dataset[`map_${map_id}`].map)        
    }, [dataset, map_id])

    useEffect(() => {     
        if(isAuth){
            if(map_id) apiService(`/map/${map_id}`,`map_${map_id}`,'get',{})
        }           
        return () => {
            clearDataset(`map_${map_id}`)
        };
    },[isAuth,apiService,clearDataset,map_id])

    const handleInputChange = (name,value) => {                
        setErrors({});
        setForm({
            ...form,
            [name]: value
        })        
    }

    const geocodeAddress = useCallback(async () => {                 
        const res = await axios.get(`${process.env.REACT_APP_MAPQUEST_API}&location=${inputValue}`)
        if(res.data && res.data.results){
            setOptions(res.data.results[0].locations)                                                
        }        
    },[inputValue])

    const debouncedSearchTerm = useDebounce(inputValue, 1000);
    useEffect(() => {
        if (debouncedSearchTerm) {
            geocodeAddress()
        } else {
            setOptions([]);
        }
    },[debouncedSearchTerm,geocodeAddress])


    const handleSubmit = async (e) => {
        e.preventDefault()   
        console.log('form', form)
        let isValid = true          
        await requiredFields.map(field => {
            if(!form[field]){
                setErrors({
                    [field]: true,
                    ...errors
                })  
                isValid = false
            }
            return field
        })
        if(isValid){            
            await apiService('/map','',map_id ? 'put' :'post', form)
            history.push('/')
        }
        return false        
    }
    const classes = useStyles()    
    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>            
            <Grid container spacing={4} justify="center">
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant="h6" gutterBottom>
                        Modulo di {action} di una mappa
                    </Typography>
                </Grid>
                <Grid item xs={12}>                    
                    <TextField
                        variant="outlined"
                        error={errors.name ? true : false}     
                        helperText={errors.name ? 'Campo obbligatorio' : ''}
                        required                   
                        name="name"
                        id="name"
                        label="Nome Mappa"
                        onChange={(e) => handleInputChange('name',e.target.value)}
                        value={form.name}
                        className={classes.textField}
                        margin="normal"
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={12}>                    
                    <TextField
                        variant="outlined"
                        error={errors.description ? true : false}     
                        helperText={errors.description ? 'Campo obbligatorio' : ''}
                        required                
                        multiline   
                        name="description"
                        id="description"
                        label="Descrizione Mappa"
                        onChange={(e) => handleInputChange('description',e.target.value)}
                        value={form.description}
                        className={classes.textField}
                        margin="normal"
                        color="secondary"
                    />
                </Grid>                   
                <Grid item  xs={12} container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            id="location-autocomplete"
                            style={{ width: '100%' }}
                            getOptionLabel={(option) => `${option.street}${option.adminArea5?' '+option.adminArea5:''}${option.adminArea4?' '+option.adminArea4:''}${option.adminArea3?' '+option.adminArea3:''}${option.postalCode?' - '+option.postalCode:''}`}
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}                            
                            onChange={(event, newValue) => {
                                setValue(null)
                                setOptions([])
                                setForm({...form, address: newValue, lat: newValue.latLng.lat, long: newValue.latLng.lng})                                
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)                                
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Add a location" variant="outlined" fullWidth />
                            )}
                            renderOption={(option) => {
                                if(!option.street && !option.adminArea3 && !option.adminArea4 && !option.adminArea5){
                                    return (
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <RemoveCircleOutlineIcon className={classes.icon} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" color="textSecondary">Nessun luogo trovato per l'indirizzo inserito</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <LocationOnIcon className={classes.icon} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" color="textSecondary">
                                                {`${option.street}${option.adminArea5?' '+option.adminArea5:''}${option.adminArea4?' '+option.adminArea4:''}${option.adminArea3?' '+option.adminArea3:''}${option.postalCode?' - '+option.postalCode:''}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>                    
                </Grid>  
                {form.address && (
                    <Grid item  xs={12}>
                        <Chip
                            icon={<LocationOnIcon />}
                            label={`${form.address.street}${form.address.adminArea5?' '+form.address.adminArea5:''}${form.address.adminArea4?' '+form.address.adminArea4:''}${form.address.adminArea3?' '+form.address.adminArea3:''}${form.address.postalCode?' - '+form.address.postalCode:''}`}                            
                            onDelete={() => setForm({ ...form, address:null, lat: null, long: null })}
                        />      
                    </Grid>
                )}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button type="submit" variant="contained" color="primary">Salva</Button>
                    {isLoading && <CircularProgress />}
                </Grid>
            </Grid>
        </form>
    )
}

MapForm.propTypes = {
    map_id: PropTypes.any
}

export default withRouter(MapForm)